<div class="container-fluid">
    <div class="pb-wrapper">
        <div class="tab-content" *ngIf="showPropertyFlag">
            <ng-container [ngSwitch]="propertyView">
                <app-pg-location *ngSwitchCase="1" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-pg-location>
                
                <app-pg-info *ngSwitchCase="2" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-pg-info>
                <app-add-attributes-compnent *ngSwitchCase="10" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-add-attributes-compnent>
                <app-pg-feautures *ngSwitchCase="3" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-pg-feautures>

                <app-pg-gallery *ngSwitchCase="4" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-pg-gallery>
                <app-pg-upload-video *ngSwitchCase="5" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-pg-upload-video>

                <app-pg-highights *ngSwitchCase="7" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-pg-highights>
                <app-pg-location-highights *ngSwitchCase="8" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-pg-location-highights>

        </ng-container>

        </div>
    </div>
</div>