import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
import { RsbService } from 'src/app/service/rsb.service';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-ongoing-project-images',
  templateUrl: './ongoing-project-images.component.html',
  styleUrls: ['./ongoing-project-images.component.css']
})
export class OngoingProjectImagesComponent implements OnInit {

  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  onGoingPropertyImages: Array<string> = [
    '',
    '',
    '',
    '',
    '',
    ''
  ];
  selectedProperty: any;

  constructor(
    private modalService: NgbModal,
    private rsbService: RsbService,
    private lookupModel: RsbLookupModel,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.selectedProperty = this.lookupModel.getBuilderCompany() ? this.lookupModel.getBuilderCompany() : null;
    if (this.selectedProperty && this.selectedProperty.onGoingProjectsGallery) {
      this.onGoingPropertyImages = this.selectedProperty.onGoingProjectsGallery;
    }
  }

  uploadAws(event: any, index: number): void {
    const mdlRef = this.modalService.open(ImageCropperComponent, { windowClass: 'signInForm' });
    mdlRef.componentInstance.imageChangedEvent = event;
    mdlRef.result.then((cropResp: { image: any, markDefault: boolean }) => {
      if (cropResp && cropResp.image) {
        const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
        modalRef.componentInstance.file = cropResp.image;
        modalRef.result.then((path: string) => {
          if (path) {
            if (this.onGoingPropertyImages[index]) {
              this.onGoingPropertyImages[index] = path;
            } else {
              const imageList = this.onGoingPropertyImages.filter(item => item !== '');

              imageList.push(path);

              if (imageList.length < 6) {
                const length = 6 - imageList.length;
                for (var i = 0; i < length; i++) {
                  imageList.push('');
                }
              }
              this.onGoingPropertyImages = imageList;
            }
          }
        });
      }
    });
  }

  submit(): void {
    let property = this.lookupModel.getBuilderCompany();
    property.onGoingProjectsGallery = this.onGoingPropertyImages;
    this.lookupModel.setBuilderCompany(property);
    if (!property.companyId) {
      this.rsbService.addBuilderCompany(property).subscribe((rsp) => {
        if (rsp.statusCode === 0) {
          this.router.navigate(['myCompany'])
        }
      })
    } else {
      this.rsbService.updateBuilderCompany(property).subscribe((rsp) => {
        if (rsp.statusCode === 0) {
          this.router.navigate(['myCompany'])
        }
      })

    }

  }
}
