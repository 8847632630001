<div class="modal-content">
    <button type="button" class="close loginclose" data-dismiss="modal" (click)="close()">&times;</button>
    <div class="modal-body">
        <div id="first">
            <div class="myform form">
                <div class="logo mb-1">
                    <div class="col-md-12 text-center">
                        <h1>Reset Password</h1>
                    </div>
                </div>
                <form name="signup" [formGroup]="signupForm" (ngSubmit)="register()">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Mobile Number</label>

                        <div class="input-group mobno">
                            <ngx-intl-tel-input [cssClass]="'form-control'" [onlyCountries]="onlyCountries"
                                [enableAutoCountrySelect]="true" [enablePlaceholder]="false"
                                [searchCountryFlag]="false" [selectFirstCountry]="false" [maxLength]="15"
                                [tooltipField]="TooltipLabel.Name" style="width: 100%;" [phoneValidation]="true"
                                [separateDialCode]="true" id="mobile" [selectedCountryISO]="selectedISOCountry"
                                name="Mobile" formControlName="mobile">
                            </ngx-intl-tel-input>
                        </div>
                        <ul class="list-inline sendbtn">
                            <li class="list-inline-item">
                                <button type="button" class="btn btn-default2" *ngIf="!mobileOtpSent"
                                    [disabled]="mobile.invalid" (click)="sendOtp(mobile.value)">
                                    SEND OTP</button>
                                <button type="button" class="btn btn-default2" *ngIf="mobileOtpSent"
                                    [disabled]="mobile.invalid" (click)="resendOtp(mobile.value)"> RE-SEND
                                    OTP</button>
                            </li>

                            <li class="list-inline-item">
                                <button type="button" class="btn btn-warning resend"
                                    (click)="changeMobile()">Change
                                    Mobile Number</button>
                            </li>
                        </ul>
                        <!-- <span class="text-danger"
                            *ngIf="(loginForm.submitted || mobile.touched) && mobile.errors && mobile.errors.required">
                            Mobile Number is required.
                        </span> -->
                        <span class="text-danger"
                            *ngIf="mobile.touched && mobile.errors && mobile.errors.validatePhoneNumber">
                            Enter Valid Mobile Number.
                        </span>
                        <span *ngIf="mobile.valid && mobileExists" style="color:red;" id="mobileexists">
                            Mobile Already Exists, Please Login
                        </span>
                        <span *ngIf="mobile.touched && mobile.errors && mobile.errors.required"
                            style="color:red;" id="emailerror">
                            Mobile is required.
                        </span>
                        <span
                            *ngIf="mobile.touched && !mobile.valid && mobile.errors && mobile.errors.validatePhoneNumber"
                            style="color:red;" id="mobileNotValid">
                            Enter Valid Mobile Number.
                        </span>
                        <span
                            *ngIf="!mobileOtpResent && mobileOtpSent && !mobileOtpVerified && !mobileOtpMaxFlag"
                            style="color:green;" id="mobileotpsuccessmsg1">
                            OTP Sent to mobile successfully.
                        </span>
                        <span *ngIf="mobileOtpResent && !mobileOtpVerified" style="color:green;"
                            id="mobileotpsuccessmsg1">
                            OTP Resent to mobile successfully.
                        </span>
                        <span *ngIf="mobileOtpMaxFlag" style="color:red;" id="maxOtpError">
                            Your OTP sending limit is over for today, please try after 24 hours again.
                        </span>
                        <span *ngIf="mobileOtpVerified" style="color:green;" id="emailotpsuccessmsg3">
                            mobile Verified Successfully
                        </span>
                        <span *ngIf="mobileOtpError" style="color:red;" id="exists">
                            Error in sending OTP. Please try again.
                        </span>
                        <span class="text-danger" *ngIf="error_message">{{error_message}}</span>
                        <span class="text-success" *ngIf="success_message">{{success_message}}</span>
                    </div>
                    <!-- <ul class="list-inline sendbtn">
                        <li class="list-inline-item">
                            <button type="button" class="btn btn-default2" *ngIf="!mobileOtpSent" [disabled]="mobile.invalid"
                                (click)="sendOtp(mobile.value)">
                                SEND OTP</button>
                            <button type="button" class="btn btn-default2" *ngIf="mobileOtpSent" [disabled]="mobile.invalid"
                                (click)="resendOtp(mobile.value)"> RE-SEND OTP</button>
                        </li>

                        <li class="list-inline-item">
                            <button type="button" class="btn btn-warning resend" (click)="changeMobile()">Change
                                Mobile Number</button>
                        </li>
                    </ul> -->
                    <div class="form-group">
                        <label for="usr">Enter OTP <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="mobileOtp" name="mobileOtp"
                            formControlName="mobileOtp">
                        <span *ngIf="mobileOtp.touched && mobileOtp.errors && mobileOtp.errors.required"
                            style="color:red;" id="emailOtperror">
                            OTP is required.
                        </span>
                        <span *ngIf="mobileOtpCheck && !mobileOtpVerified" style="color:red;"
                            id="mobileOtperror2">
                            OTP is incorrect.
                        </span>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Password</label>
                        <input type="password" toggle class="form-control" class="form-control" value="password"
                            id="loginPassword" name="loginPassword" formControlName="password">
                        <span
                            *ngIf="loginPassword.touched && loginPassword.errors && loginPassword.errors.required"
                            style="color:red;" id="emailOtperror">
                            Password is required.
                        </span>
                        <span
                            *ngIf="loginPassword.touched && loginPassword.errors && loginPassword.errors.minlength"
                            style="color:red;" id="passwordminerror">
                            Password will be minimum of 6 characters.
                        </span>
                        <span
                            *ngIf="loginPassword.touched && loginPassword.errors && loginPassword.errors.maxlength"
                            style="color:red;" id="passwordmaxerror">
                            Password will be maximum of 10 characters.
                        </span>
                    </div>

                    <div class="col-md-12 text-center ">
                        <button type="submit" class="btn btn-primary" type="submit"
                            [disabled]="signupForm.invalid">Reset</button>
                        <p class="notregister" style="color: darkgreen; font-size: small;">
                            Already Have an Account ? <a (click)="backtologin()">Login Here</a></p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>