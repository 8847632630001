import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
export class SwatchItem {
  projectName: string;
  pType:string;
  units:string;
  projectLocation:string;
  projectExtent:string
}
@Component({
  selector: 'app-addsuccessfulproject',
  templateUrl: './addsuccessfulproject.component.html',
  styleUrls: ['./addsuccessfulproject.component.css']
})
export class AddsuccessfulprojectComponent implements OnInit {

  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  propertyForm: FormGroup;
  selectedRentHeading: any;
  selectedCompany: any;
  companyLogo: Array<string> = [''];
  swatchList:Array<SwatchItem> = [];
  constructor(
    private fb: FormBuilder,
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.swatchList = this.lookupModel.getBuilderCompany() && this.lookupModel.getBuilderCompany().successProjects ? this.lookupModel.getBuilderCompany().successProjects : []; 

    // this.propertyForm = this.fb.group({
    //   projectName: [this.selectedCompany ? this.selectedCompany.projectName : '', [Validators.required]],
    //   projectType: [this.selectedCompany ? this.selectedCompany.projectType : '', [Validators.required]],
    //   units: [this.selectedCompany ? this.selectedCompany.units : '', [Validators.required]],
    //   projectLocation: [this.selectedCompany ? this.selectedCompany.projectLocation : '', [Validators.required]],
    //   projectExtent: [this.selectedCompany ? this.selectedCompany.projectExtent : '', [Validators.required]],
    // });
  }

  addSwatch(): void {
    const newswatch = new SwatchItem();
    this.swatchList.push(newswatch);
  }

  deleteSwatch(index: number): void {
    this.swatchList.splice(index, 1);
  }

  submit(): void {
    let property = this.lookupModel.getBuilderCompany();
    property.successProjects = this.swatchList;
    this.showNextPage.emit(4)
  }
  
 
//   submit(formValue:any): void {
//     this.selectedCompany.projectName = formValue.projectName
//     this.selectedCompany.projectType = formValue.projectType
//     this.selectedCompany.units = formValue.units
//     this.selectedCompany.projectLocation = formValue.projectLocation
//     this.selectedCompany.projectExtent = formValue.projectExtent
//     this.rsbService.addPreviousProject(this.selectedCompany).subscribe((rsp) => {
//       if(rsp.statusCode === 0) {
//         this.toastrService.success("Company Added Successfully");
//         this.router.navigate(['viewBuilderCompanies'])
//       }
//     })
//   }
//   get projectName() { return this.propertyForm.get('projectName'); }
//   get projectType() { return this.propertyForm.get('projectType'); }
//   get units() { return this.propertyForm.get('units'); }
//   get projectLocation() { return this.propertyForm.get('projectLocation'); }
//   get projectExtent() { return this.propertyForm.get('projectExtent'); }
// }

}
