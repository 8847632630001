import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PostPropertyComponent } from './components/vendor/post-property/post-property.component';
import { ForgotpasswordComponent } from './shared/components/forgotpassword/forgotpassword.component';
import { VendorDashboardComponent } from './components/vendor/vendor-dashboard/vendor-dashboard.component';
import { LoanComponent } from './components/vendor/loan/loan.component';
import { AuthGuard } from './common/guards/auth-guard';
import { MyPropertiesComponent } from './components/vendor/my-properties/my-properties.component';
import { MyProjectsComponent } from './components/vendor/my-projects/my-projects.component';
import { AddCompanyComponent } from './components/companies/add-company/add-company.component';
import { CompanySidebarComponent } from './components/companies/company-sidebar/company-sidebar.component';
import { CompanyinfoComponent } from './components/companies/companyinfo/companyinfo.component';
import { CompanyProfileComponent } from './components/companies/company-profile/company-profile.component';
import { AddProjectComponent } from './components/projects/add-project/add-project.component';
import { MyLeadsComponent } from './components/vendor/my-leads/my-leads.component';
import { MyviewLeadsComponent } from './components/myview-leads/myview-leads.component';
import { MyCompanyComponent } from './components/my-company/my-company.component';
import { PgLocationComponent } from './components/PG-hostels/pg-location/pg-location.component';
import { AddPgComponent } from './components/PG-hostels/add-pg/add-pg.component';
import { AddAttributesCompnentComponent } from './components/PG-hostels/add-attributes-compnent/add-attributes-compnent.component';
import { ReviewsAndRatingsComponent } from './components/vendor/reviews-and-ratings/reviews-and-ratings.component';
import { MyProfileComponent } from './components/vendor/my-profile/my-profile.component';
import { CibilComponent } from './components/vendor/cibil/cibil.component';
import { ProjectFinanceComponent } from './components/vendor/project-finance/project-finance.component';
import { LoansComponent } from './components/vendor/loans/loans.component';
import { LeadsComponent } from './components/vendor/leads/leads.component';
import { SalesComponent } from './components/vendor/sales/sales.component';
import { CctvComponent } from './components/vendor/cctv/cctv.component';
import { InteriorDesignEnquiryComponent } from './components/vendor/interior-design-enquiry/interior-design-enquiry.component';
import { SolarEnquiryComponent } from './components/vendor/solar-enquiry/solar-enquiry.component';
import { AddRentComponent } from './components/Rent/add-rent/add-rent.component';
import { PropertyModulesComponent } from './components/vendor/property-modules/property-modules.component';
import { AddPlotAndLandComponent } from './components/plot-and-land-in-property-modules/add-plot-and-land/add-plot-and-land.component';



const routes: Routes = [
 
  
  {
    path: 'post-property/:propertyName',
    canActivate:[AuthGuard],
    component: PostPropertyComponent
  }, 
  {
    path: 'addPg/:propertyName',
    component:AddPgComponent
  }, 
  {
    path: 'addRent/:propertyName',
    component:AddRentComponent
  }, 
  {
    path: 'propertyModules',
    component:PropertyModulesComponent
  }, 
  {
    path: 'addAttributes',
    component:AddAttributesCompnentComponent
  }, 
  {
    path: '',
    component: VendorDashboardComponent
  },
  {
    path: 'dashboard',
    canActivate:[AuthGuard],
    component: VendorDashboardComponent
  },
  {
    path:'myProperties',
    canActivate:[AuthGuard],
    component:MyPropertiesComponent
  },
  {
    path:'myProjects',
    component:MyProjectsComponent
  },
  {
    path:'Add-plot-And-Land/:propertyName',
    component:AddPlotAndLandComponent
  },
  {
    path:'reviewsAndRatings',
    component:ReviewsAndRatingsComponent
  },
  {
    path:'myProfile',
    component:MyProfileComponent
  },
  {
    path:'projectFinance',
    component:ProjectFinanceComponent
  },
  {
    path:'leads',
    component:LeadsComponent
  },
  {
    path:'loans',
    component:LoansComponent
  },
  {
    path:'sales',
    component:SalesComponent
  },
  {
    path:'cctvEnquiry',
    component:CctvComponent
  },
  {
    path:'interiorDesignEnquiry',
    component:InteriorDesignEnquiryComponent
  },
  {
    path:'solarEnquiry',
    component:SolarEnquiryComponent
  },
  {
    path:'cibil',
    component:CibilComponent
  },
  {
    path:'addCompany/:companyName',
    canActivate:[AuthGuard],
    component: AddCompanyComponent
  },
  {
    path:'addProject/:propertyId',
    canActivate:[AuthGuard],
    component: AddProjectComponent
  },
  {
    path:'companyInfo',
    canActivate:[AuthGuard],
    component: CompanyinfoComponent
  },
  {
    path:'myLead',
    canActivate:[AuthGuard],
    component: MyLeadsComponent
  },
  {
    path:'myViewLead',
    canActivate:[AuthGuard],
    component:MyviewLeadsComponent
  },
  {
    path:'myCompany',
    component:MyCompanyComponent
  },
  {
    path:'myCompany',
    component:PostPropertyComponent
  },

  {
    path:'companyProfile',
    canActivate:[AuthGuard],
    component: CompanyProfileComponent
  },
  {
    path: 'loan',
    canActivate:[AuthGuard],
    component:LoanComponent
  },
  {
    path:'pgLocation',
    component:PgLocationComponent

  },

  {
    path:'forgotpassword',
    canActivate:[AuthGuard],
    component:ForgotpasswordComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
