<div id="wrapper">
    <rsb-sidebar *ngIf="!hideMainSidebar && !hideSidebar && !hidePropertyBar && !hidePgBar && !hideRentBar"></rsb-sidebar>
    <app-company-sidebar *ngIf="hideMainSidebar"></app-company-sidebar>
    <app-project-sidebar *ngIf="hideSidebar"></app-project-sidebar>
    <app-propertysidebar *ngIf="hidePropertyBar"></app-propertysidebar>
    <app-pg-sidebar *ngIf="hidePgBar"></app-pg-sidebar>
    <app-rent-sidebar *ngIf="hideRentBar"></app-rent-sidebar>
    <app-plot-sidebar *ngIf="showPlotsideBar"></app-plot-sidebar>

    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <rsb-header></rsb-header>
            <router-outlet></router-outlet>
        </div>
        <rsb-footer></rsb-footer>
    </div>
    <rsb-spinner></rsb-spinner>
</div>