<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h2 class="mb-0">Dashboard</h2>
    </div>

    <!-- Content Row -->
    <div class="row pb-top-layout">
        <ng-container *ngFor="let countDash of countData">
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="h-100 py-2" [ngClass]="getDynamicClass(countDash)">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <h4 class="mb-1">{{countDash.moduleName}}</h4>
                                <h5>{{countDash.property_count ? countDash.property_count : countDash.projects_count ?
                                    countDash.projects_count : countDash.pg_count ? countDash.pg_count : 0 }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="col-xl-3 col-md-6 mb-4">
            <div class="h-100 py-2 pb-loans">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <h4 class="mb-1">Loans Passed</h4>
                            <h5>07</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-6 mb-4">
            <div class="h-100 py-2 pb-current">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <h4 class="mb-1">My Current Plan</h4>
                            <h5>Renewal Date : Apr 04, 2025</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Row -->
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="all-leads">
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <form>
                                <h3 class="d-inline-block mr-3 mb-0">All Leads <span
                                        class="badge badge-pill badge-secondary">26</span></h3>
                                <div class="custom-control custom-control-inline">
                                    <select class="form-control" id="moduleSelect"
                                        (change)="changeMainModule($event.target.value)">
                                        <option *ngFor="let module of moduleList" [value]="module.mainModuleId"
                                            [selected]="module.selected === true">
                                            {{ module.moduleName }}
                                        </option>
                                    </select>
                                </div>

                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" [checked]="!isviewLeads" id="Leads"
                                        name="example" value="customEx" (click)="allLeads()">
                                    <label class="custom-control-label" for="Leads">All Leads</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="Viewed" name="leadType"
                                        value="viewed" [checked]="isviewLeads" (change)="viewLeads()">
                                    <label class="custom-control-label" for="Viewed">Viewed Leads</label>
                                </div>

                            </form>
                            <div class="right-search">
                                <input type="text" class="form-control d-inline-block" style="width: 200px;"
                                    placeholder="Search">
                                <label for="Sort by:">Sort by:</label>
                                <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                    <option>Monthly</option>
                                    <option>Weekly</option>
                                    <option>Daily</option>
                                </select>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-hover table-responsive agent-table">
                                <thead class="">
                                    <tr>
                                        <th>Client Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email ID</th>
                                        <th>Date Added</th>
                                        <th>Follow up Date</th>
                                        <th>City</th>
                                        <th>Remarks</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lead of getMylead">
                                        <td>{{lead.customerName}}</td>
                                        <td>{{lead.cmobile}}</td>
                                        <td>{{lead.cemail}}</td>
                                        <td>15-03-2024</td>
                                        <td>25-03-2024</td>
                                        <td>Hyderabad</td>
                                        <td>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
                                            ..</td>
                                        <td>
                                            <select class="form-control btn btn-light text-left" id="staus">
                                                <option>In Progress</option>
                                                <option>New</option>
                                                <option>Lost</option>
                                                <option>closed</option>
                                            </select>
                                        </td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>