import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
import { RsbService } from 'src/app/service/rsb.service';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
export class SwatchItem {
  swatchName: string;
  bhk:string;
  image:string
}

@Component({
  selector: 'app-projectfloorplan',
  templateUrl: './projectfloorplan.component.html',
  styleUrls: ['./projectfloorplan.component.css']
})
export class ProjectfloorplanComponent implements OnInit {
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  swatchList: Array<SwatchItem> = [];
  availableAttributes: [] = []
  constructor(
    private lookupModel: RsbLookupModel,
    private modalService: NgbModal,
    private rsbService: RsbService,
  
  ) {  }

  ngOnInit(): void {
    this.getAttributes()
  }
  getAttributes() {
    const index = this.lookupModel.getVendorProperty().baiscAttrList.findIndex((obj) => {
      return obj.displayType === 2
    })
    if (index > -1) {
      this.availableAttributes = this.lookupModel.getVendorProperty().baiscAttrList[index].attributeProperties ? this.lookupModel.getVendorProperty().baiscAttrList[index].attributeProperties.filter((obj) => {
        return obj.selected && obj.selected === true;
      }).map((obj1) => {
        return obj1.swatchName;
      }) : [];
    }

  }
  addSwatch(): void {
    const newswatch = new SwatchItem();
    // this.swatchList.push(newswatch);
    this.swatchList.unshift(newswatch)
  }

  deleteSwatch(index: number): void {
    this.swatchList.splice(index, 1);
  }

  back(): void {
    this.showNextPage.emit(4);
  }

  submit() : void {
    let property = this.lookupModel.getVendorProperty();
    property.floorPlanImages = this.swatchList;
    this.lookupModel.setVendorProperty(property);
    this.showNextPage.emit(6);
  }

  uploadAws(event: any, index: number, ind:number): void {
    const mdlRef = this.modalService.open(ImageCropperComponent, { windowClass: 'signInForm'});
    mdlRef.componentInstance.imageChangedEvent = event;
    mdlRef.result.then((cropResp: {image: any, markDefault: boolean}) => {
      if(cropResp && cropResp.image) {
        const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
        modalRef.componentInstance.file = cropResp.image;
        modalRef.result.then((path: string) => {
          if (path) {
            this.swatchList[ind].image =path
          }
        });
      }
    });
  }

}

