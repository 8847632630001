import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RsbService } from 'src/app/service/rsb.service';
import { NguCarouselConfig } from '@ngu/carousel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit {

  
  propertyView: number = 3;
  selectedProperty: any;
  showPropertyFlag: boolean = false;
  listings: Array<any> = [];

  carouselConfig: NguCarouselConfig = {
    grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
    load: 3,
    slide: 1,
    interval: { timing: 4000, initialDelay: 1000 },
    loop: true,
    touch: true,
    velocity: 0.2,
    vertical: {
      enabled: true,
      height: 1050
    },
    point: {
      visible: false,
      hideOnSingleSlide: false
    }
  }
  carouselItems: any[any] = [];
  mainItems: any[] = []
  isFormDirty: boolean = false;

  constructor(
    private lookupModel: RsbLookupModel,
    private route: ActivatedRoute,
    private rsbService: RsbService,
    private router: Router,
    private _cdr: ChangeDetectorRef,
    private modalService: NgbModal
  ) {
    this.route.params.subscribe((val) => {
      if (val) {
        if (val.propertyId === 'new') {
          this.lookupModel.setVendorProperty(null);
          this.lookupModel.setBasicValues(null);
          this.openPage(1);
        }
      }
    });
  }

  ngOnInit(): void {
    this.selectedProperty = this.lookupModel.getBuilderCompany();
    if (this.selectedProperty && this.selectedProperty.projectId && this.selectedProperty !=="abc") {
      const args = {
        projectId: this.selectedProperty.projectId,
        categoryId: this.selectedProperty.categoryId,
        subCategoryId: this.selectedProperty.subCategoryId,
        moduleId: [this.selectedProperty.moduleId]
      };
     // this.getListingProperty(args);
    } else {
      this.showPropertyFlag = true;
    }
  }

  ngAfterViewInit() {
    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this.lookupModel.setVendorProperty(null);
  }

  getListingProperty(args: any): void {
    this.rsbService.getListingBasedOnListingId(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        const property = rsp.propertyInfo[0];
        property.additionalAttributes = rsp.additionalAttributes;
        property.basicAttributes = rsp.basicAttributes;
        property.plans = rsp.plans;
        property.specialAttributes = rsp.specialAttributes;
        this.lookupModel.setVendorProperty(property);
        this.showPropertyFlag = true;
      }
    });
  }

  openTab(event: any, page: number): void {
    if(event) {
      event.stopPropagation();
    }
    if (this.selectedProperty && this.selectedProperty.status) {
      this.openPage(page);
    } else {
      if(this.propertyView > page) {
        this.openPage(page)
      } else {
        return;
      }
    }
    // this.openPage(page);
  }

  openPage(page: number): void {
    const pageAction = () => {
      this.rsbService.setPropertyData(page);
      this.propertyView = page;
    }
    if(this.isFormDirty) {
 
      pageAction();
  
    } else {
      pageAction();
    }
  }





  changeFormStatus(status: boolean): void {
    this.isFormDirty = status;
  }
}
