<div class="card padding-card t-align-l no-radius border">
    <form [formGroup]="propertyForm" (ngSubmit)="submit(propertyForm.getRawValue())" #propform="ngForm">
    <div class="card-body">
        <h5 class="card-title mb-4">Project Location Highlights</h5>
        <div class="row">
            <div class="form-group col-md-12">
                <label for="location">About Locality</label>
                <div class="input-group">
                    <textarea rows="10" cols="100" class="form-control" id="aboutMe" name="aboutLocality"
                    formControlName="aboutLocality"></textarea>
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Education Institutions</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="location" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Hospitals</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="hospitals" >
                </div>
          
            </div>
            <div class="form-group col-md-12">
                <label for="location">Transport</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="transport" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Colleges</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="colleges" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Shopping Centers</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="shoppingCenter" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Mall & Multiplex</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="mallsMultiplex" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Hotels & Resorts</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="hotelResorts" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Near By Locality </label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="nearByLocality" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Banks</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="banks" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Commercial Hubs </label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="commercialHubs" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Residential Complexes</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="ResidentialComplex" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Tourist Spots</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" formControlName="touristSpots" >
                </div>
            </div>
        </div>
        
    </div>
    <button type="submit" (click)="back()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Back</button>
    <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
    </form>
</div>