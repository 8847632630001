import { Component, OnInit, Input } from '@angular/core';
import { ImageCroppedEvent, ImageTransform, base64ToFile } from 'ngx-image-cropper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.css']
})
export class ImageCropperComponent implements OnInit {
    @Input() imageChangedEvent: any = '';
    @Input() catName: any = '';
    @Input() pgGallery: any = '';
    typeControl: any;
    errorTypeMsg: boolean = false;




    // IsResidential:boolean=false;
    // IsNotResidential:boolean=false;
    activateTypesOptions: boolean = false;

    typeOptions: string[] = [
        "Hall",
        "TV Unit",
        "Kitchen",
        "Dining Area",
        "Master Bed Room",
        "Children Bed Room",
        "Wash Room",
        "Pooja Room",
        "Entrance",
        "Balcony",
        "Others"
    ];
    commercialGalleryTypeOptions: string[] = [
        "Building",
        "Entrance",
        "Cabins",
        "Office Area",
        "Waiting Room",
        "Cafeteria",
        "Dining Area",
        "Wash Rooms",
        "Meeting Room",
        "Conference Hall",
        "Front Office",
        "Others"
    ]
    PgHostelsGalleryTypeOptions: string[] = [
        "Room",
        "Waiting Hall",
        "Kitchen",
        "Cafeteria",
        "Pantry",
        "Dining Area",
        "Game Room",
        "TV Hall",
        "Club House",
        "Powder Room",
        "Gym",
        "Study Room",
        "Work Station",
        "Swimming Pool",
        "Wash Room",
        "Others"

    ]

    picType: string = '';
    croppedImage: any = '';
    outputFile: any;
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    transform: ImageTransform = {};
    origWidth;
    origHeight;
    markDefault;
    constructor(
        private activeModal: NgbActiveModal,
        private toastrService: ToastrService,
        private lookupModel: RsbLookupModel,
        private rsbService: RsbService

    ) { }

    ngOnInit() {
        this.fileChangeEvent(this.imageChangedEvent);
    }


    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.outputFile = base64ToFile(this.croppedImage);
    }

    imageLoaded(image: any) {
        // show cropper
        if (image.original && image.original.size) {
            this.origWidth = image.original.size.width;
            this.origHeight = image.original.size.height;
        }
        if (this.origHeight < 550 || this.origWidth < 800) {
            this.toastrService.error('Please select an Image with minimum 800 * 550 size.');
            this.close();
        }
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    close(rsn?: any): void {
        this.activeModal.close(rsn);
    }

    save(): void {
        if (!this.picType) {
            this.errorTypeMsg = true;
            setTimeout(() => {
                this.errorTypeMsg = false;
            }, 4000);
            this.typeControl?.markAsTouched();
            return;
        }
      

        if (this.outputFile && this.picType) {
            this.close({ image: this.outputFile, markDefault: this.markDefault, imageType: this.picType });
        }
        this.close({ image: this.outputFile, markDefault: this.markDefault });
    }

    SaveForwithOutTypeOptions(): void {
        this.close({ image: this.outputFile, markDefault: this.markDefault });
    }

    CancelForwithOutTypeOptions(): void {
        this.close();
    }



    cancel(): void {
        this.close();
    }

}
