<!-- <div class="row">
    <div class="col-md-4 mb-3" *ngFor="let propertyImage of propertyImages; let index = index">
        <div class="card video-card">
            <video class="card-img-top" style="height: 150px; width: 100%; object-fit: cover;"
                *ngIf="propertyImage.image" [src]="propertyImage.image" (mouseover)="playVideo($event)"
                (mouseleave)="pauseVideo($event)" muted preload="metadata">
                Your browser does not support the video tag.
            </video>

            <div class="card-body text-center" *ngIf="!propertyImage.image"
                style="height: 150px; display: flex; justify-content: center; align-items: center; border: 1px dashed #ccc;">
                <label for="{{ 'videoInput' + index }}" style="cursor: pointer;">
                    <i class="ri-upload-line"></i> Upload Video
                </label>
                <input type="file" id="{{ 'videoInput' + index }}" class="d-none" (change)="uploadAws($event, index)"
                    accept="video/*" />
            </div>
        </div>
    </div>
</div> -->



<div class="card padding-card t-align-l no-radius border">
    <form (ngSubmit)="submit()">


        <div *ngIf="isMobileLayout" class="mobile-navigation">

            <button type="button" class="arrow back" (click)="back()">
                <i class="ri-arrow-left-line"></i>
            </button>
            <button type="submit" class="btn btn-outline-primary btn-sm">Skip</button>
            <button type="submit" class="arrow next">
                <i class="ri-arrow-right-line"></i>
            </button>
        </div>

        <div class="card-body" style="margin-top:55px">
            <h5 class="card-title mb-4">Property Videos

            </h5>
            <div class="row" [ngStyle]="isMobileLayout ? {'display': 'block'}: {'display': 'flex'}">

                <div class="col-md-4 mb-3" *ngFor="let propertyImage of propertyImages; let index = index">
                    <div class="card video-card">
                        <video class="card-img-top" style="height: 150px; width: 100%; object-fit: cover;"
                            *ngIf="propertyImage.video" [src]="propertyImage.video" (mouseover)="playVideo($event)"
                            (mouseleave)="pauseVideo($event)" muted preload="metadata">
                            Your browser does not support the video tag.
                        </video>
            
                        <div class="card-body text-center" *ngIf="!propertyImage.video || editMode[index]"
                            style="height: 150px; display: flex; justify-content: center; align-items: center; border: 1px dashed #ccc;">
                            <label for="{{ 'videoInput' + index }}" style="cursor: pointer;">
                                <i class="ri-upload-line"></i>  {{ editMode[index] ? 'Replace Video' : 'Upload Video' }}
                            </label>
                            <input type="file" id="{{ 'videoInput' + index }}" class="d-none" #videoedit (change)="uploadAws($event, index)"
                                accept="video/*" />
                        </div>
                        <div *ngIf="propertyImage.video && !editMode[index]" class="mt-2 text-center">
                            <button type="button" class="btn btn-outline-primary btn-sm" (click)="enableEdit(index)">
                              <i class="ri-edit-line"></i> Edit
                            </button>
                          </div>
                    </div>
                </div>


            </div>
        </div>
        <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2" *ngIf="!isMobileLayout">Next</button>
        <button type="button" class="btn btn-primary btn-lg mb-2 float-right mr-2" *ngIf="!isMobileLayout"
            (click)="back()">Back</button>
    </form>
</div>