<div class="card padding-card t-align-l no-radius border">
    <form (ngSubmit)="submit()">
        <div class="card-body">
            <h5 class="card-title mb-4">Add Floor Plan Images (Image size should atleast 800 * 550)</h5>
            <div class="row">

                <div class="col-md-4 mt-2" *ngFor="let propertyImage of floorPlanImages; let imgIndx = index;">
                    <div class="fuzone">
                        <img class="card-img-top" style="height: 150px;" *ngIf="propertyImage" [src]="propertyImage" alt="Card image cap">
                        <div class="fu-text" *ngIf="!propertyImage">
                            <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                upload</span>
                        </div>
                        <input class="upload" type="file" id="{{'imageInput' + imgIndx}}" name="{{'imageInput' + imgIndx}}" #imageInput accept="image/*"
                            (change)="uploadAws($event, imgIndx)">
                    </div>
                </div>


                <!-- <div class="col-md-4">
                    <div class="fuzone">
                        <div class="fu-text">
                            <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                upload</span>
                        </div>
                        <input class="upload" type="file" id="imageInput1" name="imageInput1" #imageInput1 accept="image/*"
                            (change)="uploadAws($event)">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="fuzone">
                        <div class="fu-text">
                            <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                upload</span>
                        </div>
                        <input class="upload" type="file" id="imageInput2" name="imageInput2" #imageInput2 accept="image/*"
                            (change)="uploadAws($event)">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="fuzone">
                        <div class="fu-text">
                            <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                upload</span>
                        </div>
                        <input class="upload" type="file" id="imageInput3" name="imageInput3" #imageInput3 accept="image/*"
                            (change)="uploadAws($event)">
                    </div>
                </div>
                <div class="col-md-4 mt-2">
                    <div class="fuzone">
                        <div class="fu-text">
                            <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                upload</span>
                        </div>
                        <input class="upload" type="file" id="imageInput4" name="imageInput4" #imageInput4 accept="image/*"
                            (change)="uploadAws($event)">
                    </div>
                </div>
                <div class="col-md-4 mt-2">
                    <div class="fuzone">
                        <img class="card-img-top" src="assets/img/list/1.png" alt="Card image cap">
                        <div class="fu-text" style="display: none;">
                            <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                upload</span>
                        </div>
                        <input class="upload" type="file" id="imageInput5" name="imageInput5" #imageInput5 accept="image/*"
                            (change)="uploadAws($event)">
                    </div>
                </div>
                <div class="col-md-4 mt-2">
                    <div class="fuzone">
                        <div class="fu-text">
                            <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                                upload</span>
                        </div>
                        <input class="upload" type="file" id="imageInput6" name="imageInput6" #imageInput6 accept="image/*"
                            (change)="uploadAws($event)">
                    </div>
                </div> -->
            </div>
        </div>
        <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
        <button type="button" (click)="back()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Back</button>
    </form>
</div>