import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solar-enquiry',
  templateUrl: './solar-enquiry.component.html',
  styleUrls: ['./solar-enquiry.component.css']
})
export class SolarEnquiryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
