<div class="container-fluid">
    <div class="pb-wrapper bg-white p-4">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">My Projects <span class="badge badge-pill badge-secondary">0</span></h3>
                                    
                                </form>
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                   
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover">
                                <thead class="">
                                    <tr>
                                    <th scope="col"><input type="checkbox"></th>
                                    <th scope="col">Project Id</th>
                                    <th scope="col">Project Name</th>
                                    <th scope="col">Date Added</th> 
                                    <th scope="col">Action</th>                           
                                  
                                </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let listings of allListings; let i =index">
                                    <td><input type="checkbox"></td>
                                    <td>{{listings.listingId}}</td>
                                    <td>{{listings.propertyTitle}}</td>
                                    <td>{{listings.createdAt}}</td>
                                    <td><button class="btn btn-success btn-sm">Complete Form</button></td>
                                    </tr>
                                
                                </tbody>
                                </table>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>