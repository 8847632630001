import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
  selector: 'rsb-post-plan',
  templateUrl: './post-plan.component.html',
  styleUrls: ['./post-plan.component.css']
})
export class PostPlanComponent implements OnInit {
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();

  constructor( private modalService: NgbModal,
    private rsbService: RsbService,
    private lookupModel: RsbLookupModel) { }

  ngOnInit(): void {
  }
  submit():void{
let property=this.lookupModel.getVendorProperty();
 this.lookupModel.setVendorProperty(property);
  this.showNextPage.emit(6);



  }

}
