<div class="container">
    <h2 style="color:green;">Apply For Loan Here</h2>
    <form name="Loanform" [formGroup]="Loanform" (submit)="Applyloan()">
        <div class="form-group">
            <label>Name<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="name" name="name" placeholder="Name" formControlName="name">
        </div>
        <div *ngIf="Loanform.get('name').invalid && Loanform.get('name').touched">
            <div *ngIf="Loanform.get('name').errors.required"><span class="text-danger">Name is required.</span></div>
          </div>
        <div class="form-group">
            <label>Mobile Number<span class="text-danger">*</span></label>
            <input type="tel" class="form-control" id="mobile" name="mobile" placeholder="Mobile Number"
                formControlName="mobile">
                <div *ngIf="f.mobile.errors && (f.mobile.dirty || f.mobile.touched)">
                    <div *ngIf="f.mobile.errors.required"><span class="text-danger">Mobile number is required.</span></div>
                    <div *ngIf="f.mobile.errors.pattern"><span class="text-danger">Mobile number must be 10 digits.</span></div>
                  </div>
        </div>
        <div class="form-group">
            <label>Email address<span class="text-danger">*</span></label>
            <input type="email" class="form-control" id="email" name="email" placeholder="Enter email"
                formControlName="email">
            <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
            <div *ngIf="Loanform.get('email').invalid && Loanform.get('email').touched">
                <div *ngIf="Loanform.get('email').errors.required"><span class="text-danger">Email is required.</span></div>
                <div *ngIf="Loanform.get('email').errors.email"><span class="text-danger"> Please enter a valid email address.</span></div>
              </div>
        </div>
        <div class="form-group">
            <label>Pan Number<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="pan" name="pan" placeholder="Pan Number" formControlName="pan"
                style="text-transform: uppercase;">
                <div *ngIf="panFormControl.pan.errors && (panFormControl.pan.dirty || panFormControl.pan.touched)">
                    <div *ngIf="panFormControl.pan.errors.required"><span class="text-danger">PAN Number is required.</span></div>
                    <div *ngIf="panFormControl.pan.errors.invalidPan"><span class="text-danger">Invalid PAN Number.</span></div>
                  </div>
        </div>
        <div class="form-group">
            <label>Loan Amount<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="loanamount" name="loanamount" placeholder="Loan Amount"
                formControlName="loanamount">
                <div *ngIf="Loanform.get('loanamount').invalid && Loanform.get('loanamount').touched">
                    <div *ngIf="Loanform.get('loanamount').errors.required"><span class="text-danger">Loan Amount is required.</span></div>
                  </div>
        </div>
        <div class="form-group">
            <label>Loan Type<span class="text-danger">*</span></label>
            <select class="form-control" id="loantype" name="loantype" placeholder="loantype"
                formControlName="loantype">
                <option value="Home Loan">Home Loan</option>
                <option value="Loan Against Property">Loan Against Property</option>
                <option value="Plot Loan">Plot Loan</option>
                <option value="Construction Loan">Construction Loan</option>


            </select>
            <div *ngIf="Loanform.get('loantype').invalid && Loanform.get('loantype').touched">
                <div *ngIf="Loanform.get('loantype').errors.required"><span class="text-danger">Loan Type is required.</span></div>
              </div>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
    </form>
</div>