import { Component, OnInit, ChangeDetectorRef,AfterViewInit, ViewChild,AfterViewChecked, ElementRef,QueryList,ViewChildren} from '@angular/core';
import { Router } from '@angular/router';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
export interface IMenuOption {
  label: string;
  active: boolean;
  pageView:number;
  sideMenuicon: string
}
@Component({
  selector: 'app-plot-sidebar',
  templateUrl: './plot-sidebar.component.html',
  styleUrls: ['./plot-sidebar.component.css']
})
export class PlotSidebarComponent implements OnInit,AfterViewChecked {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChildren('menuItem') menuItems!: QueryList<ElementRef>;
menuOptions: Array<IMenuOption> = [
  {
    label: 'Location',
    active: true,
    pageView:1,
    sideMenuicon: 'ri-map-pin-line'
  },
  {
    label: 'Property Details',
    active: false,
    pageView:2,
    sideMenuicon: "ri-information-2-line"
  },
  {
    label: 'Features',
    active: false,
    pageView:3,
    sideMenuicon: 'ri-file-settings-line'
  },
  {

    label: 'Gallery',
    active: false,
    pageView:4,
    sideMenuicon: "ri-image-add-fill"
  },
  {

    label: 'Upload Video',
    active: false,
    pageView:5,
    sideMenuicon: "ri-image-add-fill"
  },
  {

    label: 'Property Highlights',
    active: false,
    pageView:6,
    sideMenuicon: "ri-hotel-line"

  },
  {

    label: 'Location Highlights',
    active: false,
    pageView:7,
    sideMenuicon: "ri-map-pin-range-line"

  },
  
  {

    label: 'Declaration & Publish',
    active: false,
    pageView:9,
    sideMenuicon: "ri-share-forward-2-fill"

  }
  
  
]
subMenuOpenFlag: boolean = false;
pageView:number = 1;
isMobileLayout: boolean = false;

constructor(private router: Router,
  private lookupModel: RsbLookupModel,
  private rsbService: RsbService,
  private cdr: ChangeDetectorRef) { }

ngOnInit(): void {
  this.isMobileLayout = window.innerWidth <= 1024;
  window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
  this.rsbService.data$.subscribe(data => {
    this.pageView = data;
    this.openMenuItem(this.pageView)
  });
}
ngAfterViewChecked(): void {
  // Ensure the view is updated before calling scroll
  this.scrollToActiveItem();
}

activateMenu(index: number): void {
  this.menuOptions.forEach((menu, i) => {
    menu.active = i === index; // Activate the selected menu item
  });
  this.cdr.detectChanges(); // Trigger change detection
}

scrollToActiveItem(): void {
  // Find the active menu item
  const activeItem = this.menuItems.find(item =>
    item.nativeElement.classList.contains('active')
  );

  if (activeItem && this.scrollContainer) {
    const container = this.scrollContainer.nativeElement;
    const itemElement = activeItem.nativeElement;

    // Get the position of the active item and scroll
    const itemOffsetLeft = itemElement.offsetLeft;
    const itemWidth = itemElement.offsetWidth;

    // Scroll the container to align the active item
    container.scrollLeft = itemOffsetLeft - (container.offsetWidth - itemWidth) / 2;
  }
}
openMenuItem(pageView:number): void {
  this.menuOptions.map((obj) => {
    if(obj.pageView === pageView) {
      obj.active = true;
    } else {
      obj.active = false;
    }
  })
}

home(): void {
  this.router.navigate(['dashboard'])
}


}
