import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-project-specifications',
  templateUrl: './project-specifications.component.html',
  styleUrls: ['./project-specifications.component.css']
})
export class ProjectSpecificationsComponent implements OnInit {
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  propertyForm: FormGroup;
  constructor(
    private lookupModel : RsbLookupModel,
    private fb: FormBuilder,
    private route: Router,
    private  rsbService:RsbService
  ) { }
  // masterBedroomWalls:string;
  // masterBedroomFlooring:string;
  // otherBedroomFlooring:string;
  // walls:string;
  // livingAreaFlooring:string;
  specifications:any;
  ngOnInit(): void {
    this.specifications = this.lookupModel.getVendorProperty() ? this.lookupModel.getVendorProperty().specifcations : {};
    this.propertyForm = this.fb.group({
      masterBedroomFlooring:[this.specifications && this.specifications.masterBedroomWalls ? this.specifications.masterBedroomWalls :'' ],
      masterBedroomWalls:[this.specifications && this.specifications.masterBedroomFlooring ? this.specifications.masterBedroomFlooring : ''],
      otherBedroomFlooring:[this.specifications && this.specifications.otherBedroomFlooring ? this.specifications.otherBedroomFlooring : ''],
      walls:[this.specifications && this.specifications.walls ? this.specifications.walls :''],
      livingAreaFlooring:[this.specifications && this.specifications.livingAreaFlooring ? this.specifications.livingAreaFlooring:'']
    })
  }

  back(): void {
    this.showNextPage.emit(9)
  }
  submit(formValue :any) : void {
   this.specifications = formValue
    let property = this.lookupModel.getVendorProperty();
    property.specifications = this.specifications;
    property.mainModuleId = localStorage.getItem('mainModuleId');

    this.lookupModel.setVendorProperty(property);
    this.rsbService.addBuilderProject(property).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        if (rsp.contents.projectId) {
          property.projectId = rsp.contents.projectId;
  
        }
        //remove localstorage mainmoduleid and mappedmoduleid
        localStorage.removeItem('mainModuleId');
        //redirect to my property page
        this.route.navigate(['myProperties'])
      }
    });
  }

  get masterBedroomFlooring() {return this.propertyForm.get('masterBedroomFlooring')}

  get masterBedroomWalls() {return this.propertyForm.get('masterBedroomWalls')}

  get otherBedroomFlooring() {return this.propertyForm.get('otherBedroomFlooring')}

  get walls() {return this.propertyForm.get('walls')}

  get livingAreaFlooring() {return this.propertyForm.get('livingAreaFlooring')}

}

