import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';
import { RsbService } from 'src/app/service/rsb.service';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { UploadVideoProgressComponent } from 'src/app/shared/components/upload-video-progress/upload-video-progress.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-rent-post-video',
  templateUrl: './rent-post-video.component.html',
  styleUrls: ['./rent-post-video.component.css']
})
export class RentPostVideoComponent implements OnInit {
  isMobileLayout: boolean = false;
  editMode: boolean[] = [];

  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  propertyImages: Array<{ video: string | null; type?: string }> = [
    { video: null },
  ];
    selectedProperty: any;
  awsFile!: FileList;

  constructor(
    private modalService: NgbModal,
    private rsbService: RsbService,
    private lookupModel: RsbLookupModel,
    private toastrService: ToastrService,
    
  ) {}

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => (this.isMobileLayout = window.innerWidth <= 1024);
    this.selectedProperty = this.lookupModel.getVendorProperty()
      ? this.lookupModel.getVendorProperty()
      : null;
    if (this.selectedProperty && this.selectedProperty.videos) {
      this.propertyImages = this.selectedProperty.videos;
    }
  }
  enableEdit(index: number): void {
    this.editMode[index] = true;
  }

  // uploadAws(event: any, index: number): void {
  //   this.awsFile = event.target.files;
  //   const file = this.awsFile.item(0);

  //   if (file) {
  //     const modalRef = this.modalService.open(UploadVideoProgressComponent, {
  //       windowClass: 'signInForm',
  //     });
  //     modalRef.componentInstance.file = file;
  //     modalRef.componentInstance.type = 'abc';
  
  //     modalRef.result.then((path: any) => {
  //       if (path && path.image && path.type) {
  //         this.propertyImages[index] = {
  //           video: path.image,
  //           type: path.type,
  //         };
  //         this.editMode[index] = false;
  //       }
  //     });
  //   }
  // }
  uploadAws(event: any, index: number): void {
    this.awsFile = event.target.files;
    const file = this.awsFile.item(0);
  
    if (file) {
      const videoElement = document.createElement('video');
      videoElement.preload = 'metadata';
  
      videoElement.onloadedmetadata = () => {
        window.URL.revokeObjectURL(videoElement.src);
        const videoDuration = videoElement.duration;
          if (videoDuration > 600) {
          this.toastrService.error('Video duration exceeds 10 minutes. Please upload a shorter video.');

          return;
        }
          const modalRef = this.modalService.open(UploadVideoProgressComponent, {
          windowClass: 'signInForm',
        });
        modalRef.componentInstance.file = file;
        modalRef.componentInstance.type = 'abc';
  
        modalRef.result.then((path: any) => {
          if (path && path.image && path.type) {
            this.propertyImages[index] = {
              video: path.image,
              type: path.type,
            };
            this.editMode[index] = false;
          }
        });
      };
  
      videoElement.onerror = () => {
        // alert('Invalid video file. Please select a valid video.');
      };
  
      videoElement.src = URL.createObjectURL(file);
    }
  }
  
  

  playVideo(event: Event): void {
    const videoElement = event.target as HTMLVideoElement;
    videoElement.muted = true; // Ensure the video is muted to avoid autoplay restrictions
    videoElement.play().catch((err) => console.error('Error playing video:', err));
  }

  pauseVideo(event: Event): void {
    const videoElement = event.target as HTMLVideoElement;
    videoElement.pause();
  }

  back(): void {
    let property = this.lookupModel.getVendorProperty();
    property.videos = this.propertyImages;
    this.lookupModel.setVendorProperty(property);
    this.showNextPage.emit(4);
  }
 

  submit(): void {
    let property = this.lookupModel.getVendorProperty();
    property.videos = this.propertyImages;
    if (!property.listingId) {
      property.listingId = 'abc';
    }
    if (property.propertyPreviewImage) {
      property.propertyPreviewImage = this.propertyImages[0];
    }

    this.lookupModel.setVendorProperty(property);
    this.showNextPage.emit(6);
  }
}