<div class="card padding-card t-align-l no-radius border">
    <div class="card-body">
        <h5 class="card-title mb-4">Project Highlights</h5>
        <div class="row">
            <div class="form-group col-md-12" *ngFor="let swatch of swatchList; let i = index;">
                <label for="location">Enter Highlights</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location" [(ngModel)]="swatchList[i].swatchName" >
                </div>
            </div>
        </div>
        <button (click)="addSwatch()">Add New</button>
    </div>
    <button type="button" (click)="back()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Back</button>
    <button type="button" (click)="submit()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>

</div>