<div class="card padding-card t-align-l no-radius border">
    <form (ngSubmit)="publish()">
        <div class="card-body">
            <h5 class="card-title mb-4">Why do we charge for listings?</h5>
            <p>We don't make money by selling your data to third parties. That is not part of our
                business model. Charging dramatically reduces fraud, which our team monitors around
                the clock.</p>
            <p>We provide a full service. If you have any questions, you can call our customer
                service team, talk to a person, and we will help you out.</p>
        </div>

        <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2">PUBLISH</button>
    </form>
</div>