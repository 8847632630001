<div class="tab-pane active" id="pb-cdetails">
    <form [formGroup]="propertyForm" (ngSubmit)="submit(propertyForm.getRawValue())" #propform="ngForm">
        <h2 class="mb-4">Company Details</h2>
        <div class="form-row mb-4">
            <div class="col">
                <label for="Company Name">Company Name<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" id="companyName" name="companyName"
                    formControlName="companyName">
                <span *ngIf="(propform.submitted || companyName.touched) && companyName.errors && companyName.errors.required" style="color: red">
                    Company Name is required.
                </span>
            </div>
            <div class="col">
                <label for="CIN Number">CIN Number<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" id="cinNumber" name="cinNumber"
                    formControlName="cinNumber" formControlName="cinNumber">
                    <span *ngIf="(propform.submitted || cinNumber.touched) && cinNumber.errors && cinNumber.errors.required" style="color: red">
                        CIN Number is required.
                    </span>
                    
            </div>
            <div class="col">
                <label for="GST Number">GST Number<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" id="gstNumber" name="gstNumber"
                    formControlName="gstNumber">
                    <span *ngIf="(propform.submitted || gstNumber.touched) && gstNumber.errors && gstNumber.errors.required" style="color: red">
                        GST Number is required.
                    </span>
            </div>
            <div class="col">
                <label for="Company Number">Contact Number<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" id="contactNumber" name="contactNumber"
                    formControlName="contactNumber">
                    <span *ngIf="(propform.submitted || contactNumber.touched) && contactNumber.errors && contactNumber.errors.required" style="color: red">
                        Contact Number is required.
                    </span>

            </div>
        </div>
        <div class="form-row mb-4">
            <div class="col">
                <label for="Company Person">Contact Person<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" id="contactPerson" name="contactPerson"
                    formControlName="contactPerson">
                    <span *ngIf="(propform.submitted || contactPerson.touched) && contactPerson.errors && contactPerson.errors.required" style="color: red">
                        Contact Person is required.
                    </span>
            </div>
            <div class="col">
                <label for="Company Number">Company Number<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" id="companyNumber" name="companyNumber"
                    formControlName="companyNumber">
                    <span *ngIf="(propform.submitted || companyNumber.touched) && companyNumber.errors && companyNumber.errors.required" style="color: red">
                        Company Number is required.
                    </span>
            </div>

            <div class="col">
                <label for="Company Number">Contact Email ID<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" id="contactEmail" name="contactEmail"
                    formControlName="contactEmail">
                    <span *ngIf="(propform.submitted || contactEmail.touched) && contactEmail.errors && contactEmail.errors.required" style="color: red">
                        Contact Email is required.
                    </span>
            </div>
            <div class="col">
                <label for="Company Number">Established Year<span class="text-danger">*</span> </label>
                <input type="text" class="form-control" placeholder="Type here" id="established" name="established"
                    formControlName="established">
                    <span *ngIf="(propform.submitted || established.touched) && established.errors && established.errors.required" style="color: red">
                        Established is required.
                    </span>
            </div>
        </div>
        <div class="form-row mb-4">
            <div class="col">
                <label for="Company Number">Awards<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" id="awards" name="awards"
                    formControlName="awards">
                    <span *ngIf="(propform.submitted || awards.touched) && awards.errors && awards.errors.required" style="color: red">
                        Awards is required.
                    </span>
            </div>
            <div class="col">
                <label for="Company Number">Recognizations<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" id="recognizations" name="recognizations"
                    formControlName="recognizations">
                    <span *ngIf="(propform.submitted || recognizations.touched) && recognizations.errors && recognizations.errors.required" style="color: red">
                        Recognizations is required.
                    </span>
            </div>
            <div class="col">
                <label for="Company Number">Rera Licence Number<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" id="licencenumber" name="licencenumber"
                    formControlName="licencenumber">
                    <!-- <span *ngIf="(propform.submitted || licencenumber.touched) && licencenumber.errors && licencenumber.errors.required" style="color: red">
                        Licencenumber is required.
                    </span> -->
            </div>
            <div class="col">
                <label for="GST Number">Company Address<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Type here" id="companyAddress"
                    name="companyAddress" formControlName="companyAddress">
                    <span *ngIf="(propform.submitted ||companyAddress.touched) && companyAddress.errors && companyAddress.errors.required" style="color: red">
                        Company Address is required.
                    </span>
            </div>
        </div>
        <div class="form-row mb-4">
            <div class="col">
                <label for="GST Number">Company Profile<span class="text-danger">*</span> </label>
                <textarea class="form-control" placeholder="Type here" id="companyProfile"
                    name="companyProfile" formControlName="companyProfile"></textarea>
                    <span *ngIf="(propform.submitted ||companyProfile.touched) && companyProfile.errors && companyProfile.errors.required" style="color: red">
                        Company Profile is required.
                    </span>
            </div>
            <div class="col">
                <label for="GST Number">Company Description<span class="text-danger">*</span> </label>
                <textarea  class="form-control" placeholder="Type here" id="companyDescription"
                    name="companyDescription" formControlName="companyDescription"></textarea>
                    <span *ngIf="(propform.submitted ||companyDescription.touched) && companyDescription.errors && companyDescription.errors.required" style="color: red">
                        Company Description is required.
                    </span>
            </div>
        </div>

        <div class="form-row mb-4">
            <div class="col-md-4">
                <label for="Company Person">Company Logo</label>
                <div class="drag-area">
                    <div class="icon"><i class="ri-upload-line"></i></div>
                    <div class="d-flex header">
                        <p>Drag & Drop to Upload File OR </p>
                        <p class="browse"> Browse File</p>
                    </div>
                    <input type="file" hidden>
                </div>
                <p class="jpeg">( Jpeg/Png format - 10Kb Size )</p>
            </div>
        </div>
        <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
    </form>
</div>