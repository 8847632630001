import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plot-publish',
  templateUrl: './plot-publish.component.html',
  styleUrls: ['./plot-publish.component.css']
})
export class PlotPublishComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
