<div class="card padding-card t-align-l no-radius border">
    <form [formGroup]="propertyForm" (ngSubmit)="submit(propertyForm.getRawValue())" #propform="ngForm">
        <div class="card-body">
            <h5 class="card-title mb-4">Property Description</h5>
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="location">Address <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Country" id="location" name="location"
                            formControlName="location">
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button" (click)="changeLocation()">
                                <i class="far fa-edit"></i>Change Address
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mb-3">
                    <label class="d-block" for="moduleId">Module Type <span class="text-danger">*</span></label>
                    <div class="custom-control custom-radio custom-control-inline" *ngFor="let module of moduleList;">
                        <input type="radio" class="custom-control-input" id="{{module.moduleId}}" name="moduleId" value="{{module.moduleId}}"
                            formControlName="moduleId">
                            <ng-container>
                        <label class="custom-control-label" for="{{module.moduleId}}">{{module.altModuleName}}</label>
                   </ng-container>
                    </div>
                    <span *ngIf="(propform.submitted || moduleId.touched) && moduleId.errors && moduleId.errors.required"
                        style="color: red">Module Type is required.</span>
                </div>
                <div class="col-md-12 mb-3">
                    <label class="d-block" for="categoryId">Listing Type <span class="text-danger">*</span></label>
                    <div class="custom-control custom-radio custom-control-inline" *ngFor="let category of categoryList;let i = index;">
                        <input type="radio" class="custom-control-input" id="{{category.categoryId}}" name="categoryId"
                            value="{{category.categoryId}}" formControlName="categoryId" (change)="IsListingType(category.categoryName)">
                        <label class="custom-control-label" for="{{category.categoryId}}">{{category.categoryName}}</label>
                    </div>
                    <span *ngIf="(propform.submitted || categoryId.touched) && categoryId.errors && categoryId.errors.required"
                        style="color: red">Listing Type is required.</span>
                    <!-- <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="Commercial" name="example" value="customEx">
                        <label class="custom-control-label" for="Commercial">Commercial</label>
                    </div> -->
                </div>
            </div>

            <div class="row" *ngIf="subCatList.length">
                <div class="col-md-12 mb-3">
                    <label class="d-block" for="categoryId">Select Property Type<span class="text-danger">*</span></label>
                    <div class="custom-control custom-radio custom-control-inline" *ngFor="let subcat of subCatList; let subInd = index;">
                        <input type="radio" class="custom-control-input" id="{{subcat.subCategoryId}}" name="subCategoryId"
                            value="{{subcat.subCategoryId}}" formControlName="subCategoryId">
                        <label class="custom-control-label" for="{{subcat.subCategoryId}}">{{subcat.subCategoryName}}</label>
                    </div>
                    <span *ngIf="(propform.submitted || subCategoryId.touched) && subCategoryId.errors && subCategoryId.errors.required"
                    style="color: red">Property Type is required.</span>
                    <!-- <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="Commercial" name="example" value="customEx">
                        <label class="custom-control-label" for="Commercial">Commercial</label>
                    </div> -->
                </div>
            </div>
            <div class="row">
                <!-- <div class="form-group col-md-4">
                    <label for="subCategoryId">Property Type <span class="text-danger">*</span></label>
                    <select class="form-control custom-select" id="subCategoryId" name="subCategoryId" formControlName="subCategoryId">
                        <option value="" disabled selected hidden>Select Type</option>
                        <option *ngFor="let subcat of subCatList; let subInd = index;" value="{{subcat.subCategoryId}}">{{subcat.subCategoryName}}</option>
                    </select>
                </div> -->
                <div class="form-group col-md-4">
                    <label for="price">{{selectedModule && selectedModule.priceTypeName ? selectedModule.priceTypeName:'Price'}} <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="price" name="price" formControlName="price">
                        <div class="input-group-append">
                            <span class="input-group-text">{{selectedCountry.currency}}</span>
                            <!-- <select class="form-control h-100 bg-white textus" name="priceCurrency" id="priceCurrency"
                                formControlName="priceCurrency" (change)="currencyChange(priceCurrency.value)">
                                <option *ngFor="let country of countryList" value="{{country.codeId}}">{{country.currency}}</option>
                            </select> -->
                        </div>
                    </div>
                    <span *ngIf="(propform.submitted || price.touched) && price.errors && price.errors.required" style="color: red">
                        {{selectedModule.priceTypeName}} is required.
                    </span>
                    <span *ngIf="price.dirty && price.errors && price.errors.pattern"
                style="color: red"> Only numbers are allowed.</span>
                </div>
                <!-- <div class="form-group col-md-4">
                    <label for="deposit">Deposit <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="deposit" name="deposit" formControlName="deposit">
                        <div class="input-group-append">
                            <span class="input-group-text">{{selectedCountry.currency}}</span>

                            <select class="form-control h-100 bg-white textus" name="depositCurrency" id="depositCurrency"
                                formControlName="depositCurrency" (change)="currencyChange(depositCurrency.value)">
                                <option *ngFor="let country of countryList" value="{{country.codeId}}">{{country.currency}}</option>
                            </select>
                        </div>
                    </div>
                    <span *ngIf="(propform.submitted || deposit.touched) && deposit.errors && deposit.errors.required"
                        style="color: red">Deposit is required.</span>
                </div> -->
                <div class="form-group col-md-4">
                    <label for="propertySize">Property Size <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="propertySize" name="propertySize" (blur)="prepareSeoTitle(true)" formControlName="propertySize">
                        <div class="input-group-append">
                            <select class="form-control h-100 input-group-text" name="measurement" id="measurement"
                                formControlName="measurement">
                                <option *ngFor="let measurement of measurementList" value="{{measurement.measurementId}}">{{measurement.measurementName}}</option>
                            </select>
                            <!-- <button class="btn btn-primary" type="button"><i class="far fa-edit"></i>
                                Change</button> -->
                        </div>
                    </div>
                    <span *ngIf="(propform.submitted || propertySize.touched) && propertySize.errors && propertySize.errors.required"
                        style="color: red"><span>Property Size is required.</span></span>
                        <span *ngIf="propertySize.dirty && propertySize.errors && propertySize.errors.pattern"
                        style="color: red"> Only numbers are allowed.</span>

                </div>

               
                <!-- <div class="form-group col-md-12">
                    <label>Size <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input type="text" class="form-control">
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="submit"><i class="far fa-edit"></i>
                                Change</button>
                        </div>
                    </div>
                </div> -->
            </div>
<div class="row">

    <ng-container formArrayName="basicAttributes"
        *ngFor="let control of propertyForm.get('basicAttributes')['controls']; index as bAttrInd">
        <ng-container [formGroupName]="bAttrInd">
            <div class="form-group col-md-4">
                <ng-container *ngIf="baiscAttrList[bAttrInd].displayType === 1">



                    <label for="{{'basicAttr' + bAttrInd}}">{{baiscAttrList[bAttrInd].attributeName}} <span
                            class="text-danger">*</span> </label>
                    <select class="form-control custom-select" id="{{'basicAttr' + bAttrInd}}"
                        name="{{'basicAttr' + bAttrInd}}" formControlName="attrValue" (change)="prepareSeoTitle(true)">
                        <option value="">Select</option>
                        <option *ngFor="let prop of baiscAttrList[bAttrInd].attributeProperties"
                            value="{{prop.propertyId}}">{{prop.swatchName}}</option>
                    </select>
                    <span
                        *ngIf="(propform.submitted || basicAttributes.controls[bAttrInd].get('attrValue').touched) && basicAttributes.controls[bAttrInd].get('attrValue').errors && basicAttributes.controls[bAttrInd].get('attrValue').errors.required"
                        style="color:red;" id="{{'attributeRequired' + bAttrInd}}">
                        {{baiscAttrList[bAttrInd].attributeName}} is required.
                    </span>

                </ng-container>
                <ng-container *ngIf="baiscAttrList[bAttrInd].displayType === 2">



                    <label for="{{'basicAttr' + bAttrInd}}">{{baiscAttrList[bAttrInd].attributeName}} <span
                            class="text-danger">*</span> </label>
                    <select class="form-control custom-select" id="{{'basicAttr' + bAttrInd}}"
                        name="{{'basicAttr' + bAttrInd}}" formControlName="attrValue" (change)="prepareSeoTitle(true)">
                        <option value="">Select</option>
                        <option *ngFor="let prop of baiscAttrList[bAttrInd].attributeProperties"
                            value="{{prop.propertyId}}">{{prop.swatchName}}</option>
                    </select>
                    <span
                        *ngIf="(propform.submitted || basicAttributes.controls[bAttrInd].get('attrValue').touched) && basicAttributes.controls[bAttrInd].get('attrValue').errors && basicAttributes.controls[bAttrInd].get('attrValue').errors.required"
                        style="color:red;" id="{{'attributeRequired' + bAttrInd}}">
                        {{baiscAttrList[bAttrInd].attributeName}} is required.
                    </span>

                </ng-container>
                <ng-container *ngIf="baiscAttrList[bAttrInd].displayType ===3">



                    <label for="{{'basicAttr' + bAttrInd}}">{{baiscAttrList[bAttrInd].attributeName}} <span
                            class="text-danger">*</span> </label>
                    <select class="form-control custom-select" id="{{'basicAttr' + bAttrInd}}"
                        name="{{'basicAttr' + bAttrInd}}" formControlName="attrValue" (change)="prepareSeoTitle(true)">
                        <option value="">Select</option>
                        <option *ngFor="let prop of baiscAttrList[bAttrInd].attributeProperties"
                            value="{{prop.propertyId}}">{{prop.swatchName}}</option>
                    </select>
                    <span
                        *ngIf="(propform.submitted || basicAttributes.controls[bAttrInd].get('attrValue').touched) && basicAttributes.controls[bAttrInd].get('attrValue').errors && basicAttributes.controls[bAttrInd].get('attrValue').errors.required"
                        style="color:red;" id="{{'attributeRequired' + bAttrInd}}">
                        {{baiscAttrList[bAttrInd].attributeName}} is required.
                    </span>

                </ng-container>
                <ng-container *ngIf="baiscAttrList[bAttrInd].displayType === 4">



                    <ng-container *ngIf="baiscAttrList[bAttrInd].textBoxType === 1">
                        <label>{{ baiscAttrList[bAttrInd].attributeName}}<span class="text-danger"
                                *ngIf="baiscAttrList[bAttrInd].required">*</span>
                        </label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="projectName" formControlName="attrValue"
                                value="{{baiscAttrList[bAttrInd].value}}" name="projectName"
                                (keyup)="checkProperties(baiscAttrList[bAttrInd].attributeId,$event)">
                            <div class="input-group-append">
                                <span class="input-group-text">{{selectedCountry.currency}}</span>
                                <!-- <select class="form-control h-100 bg-white textus" name="priceCurrency" id="priceCurrency"
                                                            formControlName="priceCurrency" (change)="currencyChange(priceCurrency.value)">
                                                            <option *ngFor="let country of countryList" value="{{country.codeId}}">{{country.currency}}</option>
                                                        </select> -->
                            </div>
                        </div>


                    </ng-container>
                    <ng-container *ngIf="baiscAttrList[bAttrInd].textBoxType === 2">


                        <label>{{baiscAttrList[bAttrInd].attributeName}}<span class="text-danger"
                                *ngIf="baiscAttrList[bAttrInd].required">*</span>
                        </label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="projectName" formControlName="attrValue"
                                value="{{baiscAttrList[bAttrInd].value}}" name="projectName"
                                (keyup)="checkProperties(baiscAttrList[bAttrInd].attributeId,$event)">
                            <div class="input-group-append">
                                <span class="input-group-text">{{selectedMeasurement.measurementName}}</span>
                                <!-- <button class="btn btn-primary" type="button"><i class="far fa-edit"></i>
                                                            Change</button> -->
                            </div>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="baiscAttrList[bAttrInd].textBoxType === 3">


                        <label>{{ baiscAttrList[bAttrInd].attributeName}}<span class="text-danger"
                                *ngIf="baiscAttrList[bAttrInd].required">*</span>
                        </label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="yyyy-mm-dd" [minDate]="currentDate"
                                ngbDatepicker #d="ngbDatepicker" formControlName="attrValue"
                                (dateSelect)="checkDateProperties(baiscAttrList[bAttrInd].attributeId, $event)"
                                value="{{ baiscAttrList[bAttrInd].value}}">
                            <div class="input-group-append">
                                <button class="input-group-text" type="button" (click)="d.toggle()"><i
                                        class="fal fa-calendar-alt"></i></button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="baiscAttrList[bAttrInd].textBoxType === 4">


                        <label>{{ baiscAttrList[bAttrInd].attributeName}}<span class="text-danger"
                                *ngIf="baiscAttrList[bAttrInd].required">*</span>
                        </label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="projectName" formControlName="attrValue"
                                value="{{ baiscAttrList[bAttrInd].value}}" name="projectName"
                                (keyup)="checkProperties(baiscAttrList[bAttrInd].attributeId,$event)">
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!baiscAttrList[bAttrInd].textBoxType">


                        <label>{{ baiscAttrList[bAttrInd].attributeName}}<span class="text-danger"
                                *ngIf="baiscAttrList[bAttrInd].required">*</span>
                        </label>
                        <input type="text" class="form-control" id="projectName" formControlName="attrValue"
                            value="{{ baiscAttrList[bAttrInd].value}}" name="projectName"
                            (keyup)="checkProperties(baiscAttrList[bAttrInd].attributeId,$event)">


                    </ng-container>
                    <span
                        *ngIf="propform.submitted &&  baiscAttrList[bAttrInd].required && !isvalidSubCategoryAttr(baiscAttrList[bAttrInd].attributeId)"
                        style="color:red;" id="dateAvailableRequired">

                        {{baiscAttrList[bAttrInd].attributeName}}
                        is required.
                    </span>
                    
                    <span *ngIf="baiscAttrList[bAttrInd].displayType && baiscAttrList[bAttrInd].displayType === 4  && baiscAttrList[bAttrInd].validationType === 1 && !isValidInput(baiscAttrList[bAttrInd].attributeId,baiscAttrList[bAttrInd].validationType)"
                    style="color: red"> Only Characters are allowed.</span>

                    <span *ngIf="baiscAttrList[bAttrInd].displayType && baiscAttrList[bAttrInd].displayType === 4  && baiscAttrList[bAttrInd].validationType === 2 && !isValidInput(baiscAttrList[bAttrInd].attributeId,baiscAttrList[bAttrInd].validationType)"
                    style="color: red"> Only Numbers are allowed.</span>

            </ng-container>
            </div>
    </ng-container>
    </ng-container>
<!--             
                <div class="form-group col-md-4">
                    <label for="dateAvailable">Date Available <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="yyyy-mm-dd" id="dateAvailable" name="dp"
                            [minDate]="currentDate" ngbDatepicker #d="ngbDatepicker" formControlName="dateAvailable">
                        <div class="input-group-append">
                            <button class="input-group-text" type="button" (click)="d.toggle()"><i
                                    class="fal fa-calendar-alt"></i></button>
                        </div>
                    </div>
                    <span
                        *ngIf="(propform.submitted || dateAvailable.touched) && dateAvailable.errors && dateAvailable.errors.required"
                        style="color: red">Date Available is required.</span>
            
                    <span
                        *ngIf="(propform.submitted || dateAvailable.touched) && dateAvailable.errors && dateAvailable.errors.ngbDate && dateAvailable.errors.ngbDate.invalid"
                        style="color: red">Date should be in YYYY-MM-DD.</span>
            
                    <span
                        *ngIf="(propform.submitted || dateAvailable.touched) && dateAvailable.errors && dateAvailable.errors.ngbDate && dateAvailable.errors.ngbDate.minDate"
                        style="color: red">Date should be future date.</span>
                </div> -->
            
            </div>

            <div class="form-group">
                <label for="propertyTitle">Property Title <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="propertyTitle" name="propertyTitle" formControlName="propertyTitle">
                <span *ngIf="(propform.submitted || propertyTitle.touched) && propertyTitle.errors && propertyTitle.errors.required"
                    style="color: red">Property Title is required.</span>
            </div>

            <div class="form-group">
                <label for="propertyDescription">Briefly describe your home. Listing any outstanding features <span
                        class="text-danger">*</span></label>
                <textarea class="form-control" rows="4" id="propertyDescription" name="propertyDescription"
                    formControlName="propertyDescription"></textarea>
                <span *ngIf="(propform.submitted || propertyDescription.touched) && propertyDescription.errors && propertyDescription.errors.required"
                    style="color: red">Description is required.</span>
            </div>

            <!-- <div class="form-group">
                <label for="previewImage">Preview Image <span class="text-danger">*(Image size should atleast 800 * 550)</span></label>
                <div class="fuzone">
                    <img class="card-img-top" style="height: 150px;" *ngIf="propertyPreviewImage" [src]="propertyPreviewImage"
                        alt="Card image cap">
                    <div class="fu-text" *ngIf="!propertyPreviewImage">
                        <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                            upload</span>
                    </div>
                    <input class="upload" type="file" id="imageInput" name="imageInput" #imageInput accept="image/*"
                        (change)="uploadAws($event)">
                </div>

                <span *ngIf="propform.submitted && !propertyPreviewImage" style="color: red">Preview Image is
                    required.</span>
            </div> -->

            <div class="form-group">
                <label for="propertyTitle">Name (if you want to change the vendor display details for user please change it)<span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="name" name="name" formControlName="name">
                <span *ngIf="(propform.submitted || name.touched) && name.errors && name.errors.required"
                    style="color: red">name is required.</span>
            </div>

            <div class="form-group">
                <label for="propertyTitle">Email(if you want to change the vendor display details for user please change it) <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="email" name="email" formControlName="email">
                <span *ngIf="(propform.submitted || email.touched) && email.errors && email.errors.required"
                    style="color: red">Email is required.</span>
            </div>

            <div class="form-group">
                <label for="propertyTitle">Mobile (if you want to change the vendor display details for user please change it)<span class="text-danger">*</span></label>

                <ngx-intl-tel-input [cssClass]="'form-control'" [onlyCountries]="onlyCountries"
                [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="false"
                [selectFirstCountry]="false" [maxLength]="15" [tooltipField]="TooltipLabel.Name"
                style="width: 100%;" [phoneValidation]="true" [separateDialCode]="true" id="mobile"
                [selectedCountryISO]="selectedISOCountry" name="mobile" formControlName="mobile">
            </ngx-intl-tel-input>
                             
            </div>

            <div class="form-group">
                <label for="propertyTitle">Whats App Number</label>
                <input type="text" class="form-control" id="whatsAppNumber" name="whatsAppNumber" formControlName="whatsAppNumber">
                <span *ngIf="(propform.submitted || whatsAppNumber.touched) && whatsAppNumber.errors && whatsAppNumber.errors.required"
                    style="color: red">whatsApp Number is required.</span>
            </div>

        </div>
        <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
        <button type="button" class="btn btn-primary btn-lg mb-2 float-right mr-2" (click)="back()">Back</button>
    </form>
</div>