import { Component, OnInit, Output, EventEmitter, } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-projectlocationhighlights',
  templateUrl: './projectlocationhighlights.component.html',
  styleUrls: ['./projectlocationhighlights.component.css']
})
export class ProjectlocationhighlightsComponent implements OnInit {
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  propertyForm: FormGroup;
  // aboutLocality:string = '';
  // educationInst:string = '';
  // hospitals:string = '';
  // transport:string = '';
  // colleges:string = '';
  // shoppingCenter:string = '';
  // mallsMultiplex: string = '';
  // hotelResorts:string = '';
  // nearByLocality:string = '';
  // banks:string = '';
  // commercialHubs:string = '';
  // ResidentialComplex:string = '';
  // touristSpots:string = '';
  localityHighlights:any = '';
  constructor(
    private lookupModel : RsbLookupModel,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.localityHighlights = this.lookupModel.getVendorProperty() ? this.lookupModel.getVendorProperty().locationHighlights : {};
    this.propertyForm = this.fb.group({
      aboutLocality:[  this.localityHighlights  && this.localityHighlights.aboutLocality ? this.localityHighlights.aboutLocality: ''],
      educationInst:[  this.localityHighlights  && this.localityHighlights.educationInst ? this.localityHighlights.educationInst: ''],
      hospitals:[  this.localityHighlights && this.localityHighlights.hospitals ? this.localityHighlights.hospitals: ''],
      transport:[  this.localityHighlights && this.localityHighlights.transport ? this.localityHighlights.transport: ''],
      colleges:[  this.localityHighlights && this.localityHighlights.colleges ? this.localityHighlights.colleges: ''],
      shoppingCenter:[  this.localityHighlights && this.localityHighlights.shoppingCenter ? this.localityHighlights.shoppingCenter: ''],
      mallsMultiplex:[  this.localityHighlights && this.localityHighlights.mallsMultiplex ? this.localityHighlights.mallsMultiplex: ''],
      hotelResorts:[  this.localityHighlights  && this.localityHighlights.hotelResorts ? this.localityHighlights.hotelResorts: ''],
      nearByLocality:[  this.localityHighlights && this.localityHighlights.nearByLocality ? this.localityHighlights.nearByLocality: ''],
      banks:[  this.localityHighlights && this.localityHighlights.banks ? this.localityHighlights.banks: ''],
      commercialHubs:[  this.localityHighlights && this.localityHighlights.commercialHubs ? this.localityHighlights.commercialHubs: ''],
      ResidentialComplex:[  this.localityHighlights && this.localityHighlights.ResidentialComplex ? this.localityHighlights.ResidentialComplex: ''],
      touristSpots:[  this.localityHighlights && this.localityHighlights.touristSpots ? this.localityHighlights.touristSpots: '']
    })
  }
  back() {
    this.showNextPage.emit(7)
  }
  submit(formValue:any) {
    // this.localityHighlights['aboutLocality'] = this.aboutLocality? this.aboutLocality :'' ;
    // this.localityHighlights['educationInst'] = this.educationInst ;
    // this.localityHighlights['hospitals'] = this.hospitals;
    // this.localityHighlights['transport'] = this.transport;
    // this.localityHighlights['colleges'] = this.colleges;
    // this.localityHighlights['shoppingCenter'] = this.shoppingCenter;
    // this.localityHighlights['mallsMultiplex'] = this.mallsMultiplex;
    // this.localityHighlights['hotelResorts'] = this.hotelResorts;
    // this.localityHighlights['nearByLocality'] = this.nearByLocality;
    // this.localityHighlights['banks'] = this.banks;
    // this.localityHighlights['commercialHubs'] = this.commercialHubs;
    // this.localityHighlights['ResidentialComplex'] = this.ResidentialComplex;
    // this.localityHighlights['touristSpots'] = this.touristSpots;
    let property = this.lookupModel.getVendorProperty();
    property.locationHighlights = formValue;
    this.lookupModel.setVendorProperty(property);
    this.showNextPage.emit(9);
  }

  get aboutLocality() { return this.propertyForm.get('aboutLocality')}
  get educationInst() { return this.propertyForm.get('educationInst')}
  get hospitals() { return this.propertyForm.get('hospitals')}
  get transport() { return this.propertyForm.get('transport')}
  get colleges() { return this.propertyForm.get('colleges')}
  get shoppingCenter() { return this.propertyForm.get('shoppingCenter')}
  get hotelResorts() { return this.propertyForm.get('hotelResorts')}
  get nearByLocality() { return this.propertyForm.get('nearByLocality')}
  get banks() { return this.propertyForm.get('banks')}
  get commercialHubs() { return this.propertyForm.get('commercialHubs')}
  get ResidentialComplex() { return this.propertyForm.get('ResidentialComplex')}
  get touristSpots() { return this.propertyForm.get('touristSpots')}



}

