<div class="container-fluid">
    <div class="pb-wrapper bg-white p-4">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">My Properties <span class="badge badge-pill badge-secondary">17</span></h3>
                                    <ng-container *ngFor="let module of moduleList; let i = index">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" class="custom-control-input" [id]="'module_' + i" [checked]="module.selected === true" [name]="'moduleRadio'" value="{{module.moduleId}}" (change)="changeMainModule(module)">
                                            <label class="custom-control-label" [for]="'module_' + i">{{ module.moduleName }}</label>
                                        </div>
                                    </ng-container>
                                    
                                    
                                    
                                </form>
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                   
                                </div>
                            </div>
                            
                           
                            <app-rent-table-list [allListings]="allListings" *ngIf="selectedMainModuleId[0].moduleName.toUpperCase().includes('RENT')"></app-rent-table-list>
                            <app-pg-hostel-list  [allListings]="allListings" *ngIf="selectedMainModuleId[0].moduleName.toUpperCase().includes('HOSTEL')"></app-pg-hostel-list>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>