<div class="container-fluid">
    <div class="pb-wrapper">
        <h2 class="mb-4">My Profile</h2>
        <form #profileForm="ngForm" (ngSubmit)="save()">
            <div class="form-row mb-4">
                <div class="col-md-4">
                    <label for="Company Person">Profile Photo</label>
                    <div class="drag-area">
                        <div class="icon"><i class="ri-upload-line"></i></div>
                        <div class="d-flex header">
                            <!-- <p>Drag & Drop to Upload File OR </p> -->
                            <input type="file" name="file" #imageInput accept="image/png, image/gif, image/jpeg"
                                (change)="uploadAws($event)" />
                            <!-- <p class="browse"> Browse File</p> -->
                        </div>

                    </div>
                    <p class="jpeg">( Jpeg/Png format - 10Kb Size )</p>
                </div>
            </div>
            <div class="form-row mb-5">
                <div class="col">
                    <label for="Name">Name</label>
                    <input type="text" class="form-control" name="name" [(ngModel)]="name" required
                        #nameField="ngModel">
                    <span *ngIf="nameField.invalid && (nameField.dirty || nameField.touched)" class="text-danger">
                        Name is required.
                    </span>
                </div>
                <div class="col">
                    <label for="Agency Name">Agency Name</label>
                    <input type="text" class="form-control" name="agency" placeholder="Type here" [(ngModel)]="agency"
                        required #agencyField="ngModel">
                    <span *ngIf="agencyField.invalid && (agencyField.dirty || agencyField.touched)" class="text-danger">
                        Agency is required.
                    </span>
                </div>
                <div class="col">
                    <label for="RERA Number">RERA Number</label>
                    <input type="text" class="form-control" name="reraNumber" placeholder="Type here"
                        [(ngModel)]="reraNumber" required #reraField="ngModel">
                    <span *ngIf="reraField.invalid && (reraField.dirty || reraField.touched)" class="text-danger">
                        Rera is required.
                    </span>
                </div>
            </div>
            <div class="form-row mb-5">
                <div class="col">
                    <label for="Operating since">Operating since</label>
                    <input type="text" class="form-control" name="operatingSince" placeholder="Type here"
                        [(ngModel)]="operatingSince" required #operatingField="ngModel">
                    <span *ngIf="operatingField.invalid && (operatingField.dirty || operatingField.touched)"
                        class="text-danger">
                        operating since is required.
                    </span>
                </div>
                <div class="col">
                    <label for="city">City <span class="text-danger">*</span></label>
                    <input type="text" #cityText class="form-control" placeholder="Enter City" id="city" name="city"
                        formControlName="city">
                    <!-- <span *ngIf="(locForm.submitted || city.touched) && city.errors && city.errors.required" style="color: red">City
                        is required.</span> -->
                </div>
                <div class="col">
                    <label for="Operating since">pincode</label>
                    <input type="text" class="form-control" name="pincode" placeholder="" [(ngModel)]="pincode" required #pinField="ngModel">
                    <span *ngIf="pinField.invalid && (pinField.dirty || pinField.touched)" class="text-danger">
                        Pin Code is required.
                    </span>
                </div>


            </div>
            <div class="form-row my-4">
                <div class="col-lg-3">
                    <label for="">Sold Properties</label>
                    <div class="input-group mb-3 w-50">
                        <div class="input-group-append">
                            <button class="btn btn-light borderradius-left" type="button" (click)="subtractSold()"><i
                                    class="ri-subtract-line"></i></button>
                        </div>
                        <input type="text" id="soldProp" class="form-control text-center" #inputRef
                            name="soldPropertyValue" [(ngModel)]="soldPropertyValue">
                        <div class="input-group-append">
                            <button class="btn btn-light" type="button" (click)="addSold()"><i
                                    class="ri-add-line"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label for="">Rent Properties</label>
                    <div class="input-group mb-3 w-50">
                        <div class="input-group-append">
                            <button class="btn btn-light borderradius-left" type="button" (click)="subtractRent()"><i
                                    class="ri-subtract-line"></i></button>
                        </div>
                        <input type="text" class="form-control text-center" name="rentPropertyValue"
                            [(ngModel)]="rentPropertyValue" [value]="rentPropertyValue" #rentInputRef>
                        <div class="input-group-append">
                            <button class="btn btn-light" type="button" (click)="addRent()"><i
                                    class="ri-add-line"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label for="">Clients Served</label>
                    <div class="input-group mb-3 w-50">
                        <div class="input-group-append">
                            <button class="btn btn-light borderradius-left" type="button" (click)="subtractClients()"><i
                                    class="ri-subtract-line"></i></button>
                        </div>
                        <input type="text" class="form-control text-center" #clientInputRef name="clientPropertyValue"
                            [(ngModel)]="clientPropertyValue">
                        <div class="input-group-append">
                            <button class="btn btn-light" type="button" (click)="addClients()"><i
                                    class="ri-add-line"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <button type="submit" class="btn btn-primary">Save</button>

            <!-- <button class="btn btn-primary pb-save" type="button" (click)="save()">save</button> -->
        </form>
    </div>
</div>