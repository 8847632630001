<div class="container-fluid">
    <div class="pb-wrapper">
        <div class="tab-content" *ngIf="showPropertyFlag">
            <ng-container [ngSwitch]="propertyView">
                <app-project-location *ngSwitchCase="1" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-project-location>
                <app-projectinfo *ngSwitchCase="2" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-projectinfo>
                <app-projectadditionalinfo *ngSwitchCase="3" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-projectadditionalinfo>
                <app-projectgallery *ngSwitchCase="4" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-projectgallery>
                <app-projectfloorplan *ngSwitchCase="5" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-projectfloorplan>
                <project-brochure *ngSwitchCase="6" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></project-brochure>
                <app-projecthighlights *ngSwitchCase="7" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-projecthighlights>
                <app-projectlocationhighlights *ngSwitchCase="8" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-projectlocationhighlights>
                <app-project-specifications *ngSwitchCase="9" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-project-specifications>
                <!-- <app-project-publish *ngSwitchCase="10" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-project-publish> -->

        </ng-container>

        </div>
    </div>
</div>