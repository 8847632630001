<div class="container-fluid">
    <div class="pb-wrapper">
        <div class="tab-content" *ngIf="showPropertyFlag">
            <ng-container [ngSwitch]="propertyView">
            <app-companyinfo *ngSwitchCase="1" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-companyinfo>
            <app-companyimages *ngSwitchCase="2" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-companyimages>
            <app-addsuccessfulproject *ngSwitchCase="3" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-addsuccessfulproject>
            <app-success-projects-images *ngSwitchCase="4" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-success-projects-images>
            <app-add-ongoing-projects *ngSwitchCase="5" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-add-ongoing-projects>
            <app-ongoing-project-images *ngSwitchCase="6" (formChange)="changeFormStatus($event)" (showNextPage)="openPage($event)"></app-ongoing-project-images>
        </ng-container>

        </div>
    </div>
</div>