<div class="card padding-card t-align-l no-radius border">
    <form [formGroup]="propertyForm" (ngSubmit)="submit(propertyForm.getRawValue())" #propform="ngForm">
    <div class="card-body">
        <h5 class="card-title mb-4">Project Specifications</h5>
        <div class="row">
            <div class="form-group col-md-12">
                <label for="location">Master Bedroom-Walls</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Master Bedroom-Walls" id="location" formControlName="masterBedroomWalls" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Master Bedroom-Flooring</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Master Bedroom-Flooring" id="location" formControlName="masterBedroomFlooring" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Other Bedrooms-Flooring</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Other Bedrooms-Flooring" id="location" formControlName="otherBedroomFlooring" >
                </div>
          
            </div>
            <div class="form-group col-md-12">
                <label for="location">Walls</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Walls" id="location" formControlName="walls" >
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="location">Living Area-Flooring</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Living Area-Flooring" id="location" formControlName="livingAreaFlooring" >
                </div>
            </div>
        </div>
        
    </div>
    <button type="button"  class="btn btn-primary btn-lg mb-2 float-right mr-2" (click)="back()">Back</button>
    <button type="submit"  class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
    </form>
</div>