<!-- <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h5 class="mb-0">Please Select Here Which Property You Want To Post</h5>
    </div>
        <h4>You are here to :</h4>
    
            <span *ngFor="let module of moduleList; let i =index">
                <button class="btn btn-primary rounded-pill border-0 btn-lg mb-2  mr-2" [ngClass]="{'active':module?.mainModuleId === selectedModule?.mainModuleId }"
                    (click)="selectModuleType(module)">{{module.moduleName}}</button>
            </span>
</div> -->
<div class="container-fluid">
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mt-4 mb-4">
        <h5 class="mb-0">Please Select Here Which Property You Want To Post</h5>
    </div>

    <!-- <div >
    <span>
    <button class="btn btn-primary rounded-pill border-0 btn-lg mb-2 mr-2" (click)="rentProperty()">RENT</button>
    <button class="btn btn-primary rounded-pill border-0 btn-lg mb-2 mr-2" (click)="SaleProperty()">SALE</button>
    <button class="btn btn-primary rounded-pill border-0 btn-lg mb-2  mr-2" (click)="pgHostelProperty()">PG/HOSTELS</button>

    </span>
   </div> -->

    <h4>You are here to :</h4>

    <!-- <span *ngFor="let module of moduleList; let i =index">
        <button class="mb-2  mr-2"
            [ngClass]="{'active':module?.mainModuleId === selectedModule?.mainModuleId }"
            (click)="selectModuleType(module)">
            <img  *ngIf="getIcon(module.moduleName)" [src]="getIcon(module.moduleName)" width="40px" height="auto" alt="places">
            {{module.moduleName}}</button>
    </span> -->
    <span *ngFor="let module of moduleList; let i = index" class="module-container">
        <button class="module-button"
            [ngClass]="{'active': module?.mainModuleId === selectedModule?.mainModuleId}"
            (click)="selectModuleType(module)">
            <img *ngIf="getIcon(module.moduleName)" [src]="getIcon(module.moduleName)" width="40px" height="auto" alt="places">
            <div class="module-name">{{ module.moduleName }}</div>
        </button>
    </span>
</div>
<div class="container-fluid mt-5" *ngIf="childModuleList.length">

    <h4 style="color: rgb(21, 71, 21);">Please Select the Module :</h4>

    <span *ngFor="let module of childModuleList; let i = index" class="module-container">
        <button class="module-button"
            (click)="selectChildModuleList(module)">
            <img *ngIf="getIconAltModules(module.altModuleName)" [src]="getIconAltModules(module.altModuleName)" width="40px" height="auto" alt="places">
            <div class="module-name">{{module.altModuleName}}</div>
        </button>
    </span>
</div>