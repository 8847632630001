import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RsbService } from 'src/app/service/rsb.service';
import { NguCarouselConfig } from '@ngu/carousel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';

@Component({
  selector: 'rsb-post-property',
  templateUrl: './post-property.component.html',
  styleUrls: ['./post-property.component.css']
})
export class PostPropertyComponent implements OnInit, OnDestroy {
  propertyView: number = 1;
  selectedProperty: any;
  showPropertyFlag: boolean = false;
  listings: Array<any> = [];

  carouselConfig: NguCarouselConfig = {
    grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
    load: 3,
    slide: 1,
    interval: { timing: 4000, initialDelay: 1000 },
    loop: true,
    touch: true,
    velocity: 0.2,
    vertical: {
      enabled: true,
      height: 1050
    },
    point: {
      visible: false,
      hideOnSingleSlide: false
    }
  }
  carouselItems: any[any] = [];
  mainItems: any[] = []
  isFormDirty: boolean = false;

  constructor(
    private lookupModel: RsbLookupModel,
    private route: ActivatedRoute,
    private rsbService: RsbService,
    private router: Router,
    private _cdr: ChangeDetectorRef,
    private modalService: NgbModal
  ) {
    this.route.params.subscribe((val) => {
      if (val) {
        if (val.propertyName === 'new') {
          this.lookupModel.setVendorProperty(null);
          this.lookupModel.setBasicValues(null);
          this.openPage(1);
        }
      }
    });
  }

  ngOnInit(): void {
    this.selectedProperty = this.lookupModel.getVendorProperty();
    if (this.selectedProperty && this.selectedProperty.listingId) {
      const args = {
        listingId: this.selectedProperty.listingId,
        categoryId: this.selectedProperty.categoryId,
        subCategoryId: this.selectedProperty.subCategoryId,
        moduleId: [this.selectedProperty.moduleId]
      };
      this.getListingProperty(args);
    } else {
      this.showPropertyFlag = true;
    }
  }

  ngAfterViewInit() {
    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this.lookupModel.setVendorProperty(null);
  }

  getListingProperty(args: any): void {
    this.rsbService.getListingBasedOnListingId(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        const property = rsp.propertyInfo[0];
        property.additionalAttributes = rsp.additionalAttributes;
        property.basicAttributes = rsp.basicAttributes;
        property.plans = rsp.plans;
        property.specialAttributes = rsp.specialAttributes;
        this.lookupModel.setVendorProperty(property);
        this.showPropertyFlag = true;
      }
    });
  }

  openTab(event: any, page: number): void {
    if(event) {
      event.stopPropagation();
    }
    if (this.selectedProperty && this.selectedProperty.status) {
      this.openPage(page);
    } else {
      if(this.propertyView > page) {
        this.openPage(page)
      } else {
        return;
      }
    }
    // this.openPage(page);
  }

  openPage(page: number): void {
    const pageAction = () => {
      if (page === 1) {
        this.getCountryLists();
      } else if (this.propertyView !== page) {
        this.getCityLists();
      }
      this.rsbService.setPropertyData(page);
      this.propertyView = page;
    }
    if(this.isFormDirty) {
      const mdlRef = this.modalService.open(ConfirmationComponent, { centered: true});
      mdlRef.componentInstance.modalDetails = { title: 'Confirmation', message: 'Changes to property will not be saved. Do you want to continue?'};
      mdlRef.result.then((confirm) => {
        if(confirm) {
          pageAction();
        }
      });
    } else {
      pageAction();
    }
  }

  getCountryLists(): void {
    const property = this.lookupModel.getVendorProperty();
    const args = {
      countryId: property ? property.countryId : this.lookupModel.getCountry().codeId,
      moduleId: [property && property.moduleId ? property.moduleId : this.lookupModel.getModule().moduleId ? this.lookupModel.getModule().moduleId :'']
    };
    this.rsbService.getListingsBasedOnCountry(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        this.listings = rsp.contents;
        let courses = [];

        for (var i = 0; i < 5; i++) {
          courses.push(rsp.contents[0]);
        }
        this.carouselItems = courses;
        this.mainItems = [...this.carouselItems]
      }
    });
  }

  getCityLists(): void {
    const property = this.lookupModel.getVendorProperty();
    const args = {
      countryId: property.countryId,
      city: property.city,
      moduleId: [property && property.moduleId ? property.moduleId : this.lookupModel.getModule().moduleId]
    };
    this.rsbService.getListingsBasedOnCountryAndCity(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        this.listings = rsp.contents;
        this.carouselItems = rsp.contents;
        this.mainItems = [...this.carouselItems]
      }
    });
  }

  carouselTileLoad(data) {
    let arr = this.carouselItems;
    this.carouselItems = [...this.carouselItems, ...this.mainItems];
  }

  openProperty(property: any): void {
    this.lookupModel.setProperty(property);
    const title = property.propertyTitle.replaceAll(' ', '-')
    this.router.navigate(
      ['property-details', title],
      {
        queryParams: {
          listingId: property.listingId,
          cat: property.categoryId,
          subcat: property.subCategoryId,
          module: property.moduleId,
          country: property.country
        }
      }
    );
  }

  changeFormStatus(status: boolean): void {
    this.isFormDirty = status;
  }

}
