<div class="tab-pane active" id="pb-cdetails">
    <form [formGroup]="propertyForm" (ngSubmit)="submit(propertyForm.getRawValue())" #propform="ngForm">
        <h5 class="card-title mb-4">Property Description</h5>
        <div class="form-row mb-4">
            <div class="col">
                <label for="location">Address <span class="text-danger">*</span></label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Country" id="location" name="location"
                        formControlName="location">
                    <div class="input-group-append">
                        <button class="btn btn-primary" type="button" (click)="changeLocation()">
                            <i class="far fa-edit"></i>Change Address
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row mb-4">
            <div class="col">
                <label class="d-block" for="moduleId">Module Type <span class="text-danger">*</span></label>
                <div  class="properties_btn">
                    <ng-container *ngFor="let module of moduleList">
                    <button type="button" class="btn btn-primary rounded-pill" [ngClass]="{'active':module.selected === true}" (click)="onChangeModule(module)">{{ module.moduleName}}</button>
                </ng-container>
                </div>
                <span *ngIf="(propform.submitted || moduleId.touched) && moduleId.errors && moduleId.errors.required"
                    style="color: red">Module Type is required.</span>
        
            </div>
        </div>
        <div class="form-row mb-4">
            <div class="col">
                <div class="mb-3">
                    <label class="d-block" for="categoryId">Listing Type <span class="text-danger">*</span></label>
                    <div class="properties_btn">
                        <ng-container *ngFor="let category of categoryList;let i = index;">
                        
                        <!-- <input type="radio" class="custom-control-input" id="{{category.categoryId}}" name="categoryId"
                            value="{{category.categoryId}}" formControlName="categoryId">
                        <label class="custom-control-label" for="{{category.categoryId}}">{{category.categoryName}}</label> -->
                        <button type="button" class="btn btn-primary rounded-pill" (click)="changeCategory(category)" [ngClass]="{'active':category.selected === true}">{{ category.categoryName}}</button>
                        </ng-container>
                    </div>
                    <span *ngIf="(propform.submitted || categoryId.touched) && categoryId.errors && categoryId.errors.required"
                        style="color: red">Listing Type is required.</span>
                    <!-- <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="Commercial" name="example" value="customEx">
                                <label class="custom-control-label" for="Commercial">Commercial</label>
                            </div> -->
                </div>
            </div>
        </div>
        <div class="form-row mb-4">
            <div class="col">
                <div class="" *ngIf="subCatList.length">
                    <div class="mb-3">
                        <label class="d-block" for="categoryId">Select Property Type<span class="text-danger">*</span></label>
                        <!-- <div 
                            *ngFor="let subcat of subCatList; let subInd = index;">
                            <input type="radio" class="custom-control-input" id="{{subcat.subCategoryId}}" name="subCategoryId"
                                value="{{subcat.subCategoryId}}" formControlName="subCategoryId">
                            <label class="custom-control-label"
                                for="{{subcat.subCategoryId}}">{{subcat.subCategoryName}}</label>
                        </div> -->
                        <div class="properties_btn">
                            <ng-container *ngFor="let subcat of subCatList; let subInd = index;">
                             <button type="button" class="btn btn-primary rounded-pill" [ngClass]="{'active':subcat.selected === true}" (click)="changeSubCategory(subcat)">{{subcat.subCategoryName}}</button>
                            </ng-container>
                        </div>
                        <span
                            *ngIf="(propform.submitted || subCategoryId.touched) && subCategoryId.errors && subCategoryId.errors.required"
                            style="color: red">Property Type is required.</span>
                        <!-- <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" id="Commercial" name="example" value="customEx">
                                    <label class="custom-control-label" for="Commercial">Commercial</label>
                                </div> -->
                    </div>
                </div>
        
            </div>
        </div>
        <div class="form-row mb-4">
            <div class="col">
                    <label for="location">Project Name <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input type="text" class="form-control" name="projectName" formControlName="projectName" />
                    </div>
                    <span
                        *ngIf="(propform.submitted || projectName.touched) && projectName.errors && projectName.errors.required"
                        style="color: red">
                        Project Name is required.
                </span>
            </div>
            <!-- <div class="col">
                <div class="col-md-12 mb-3">
                    <label for="location">Available Bhk <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <ul class="list-inline">
                            <li class="list-inline-item" *ngFor="let prop of availableBhk; index as i">
                                <a (click)="preTitle(prop,i)" [class.green-button]="prop.selected">{{prop.swatchName}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->

           
        </div>
        <!-- <div class="form-row-mb-4">
        
            
        </div> -->
        <div class="form-row mb-4">
            <div class="col">
                <label for="price">Price Maximum <span class="text-danger">*</span></label>
                <div class="input-group">
                    <input type="text" class="form-control" id="price" name="price" formControlName="priceMax">
                    <div class="input-group-append">
                        <span class="input-group-text">{{selectedCountry.currency}}</span>
                        <!-- <select class="form-control h-100 bg-white textus" name="priceCurrency" id="priceCurrency"
                                                formControlName="priceCurrency" (change)="currencyChange(priceCurrency.value)">
                                                <option *ngFor="let country of countryList" value="{{country.codeId}}">{{country.currency}}</option>
                                            </select> -->
                    </div>
                </div>
                <span *ngIf="(propform.submitted || priceMax.touched) && priceMax.errors && priceMax.errors.required"
                    style="color: red">
                    Price Maximum is required.
                </span>
            </div>
            <div class="col">
                <label for="price">Price Minimum <span class="text-danger">*</span></label>
                <div class="input-group">
                    <input type="text" class="form-control" id="price" name="price" formControlName="priceMin">
                    <div class="input-group-append">
                        <span class="input-group-text">{{selectedCountry.currency}}</span>
                        <!-- <select class="form-control h-100 bg-white textus" name="priceCurrency" id="priceCurrency"
                                        formControlName="priceCurrency" (change)="currencyChange(priceCurrency.value)">
                                        <option *ngFor="let country of countryList" value="{{country.codeId}}">{{country.currency}}</option>
                                    </select> -->
                    </div>
                </div>
                <span *ngIf="(propform.submitted || priceMin.touched) && priceMin.errors && priceMin.errors.required"
                    style="color: red">
                    Price Minimum is required.
                </span>
        
            </div>
        </div>
        <div class="form-row mb-4">
        
            <div class="col">
        
        
                <label for="propertySize">Measurement<span class="text-danger">*</span></label>
        
                <div class="properties_btn">
                    <ng-container *ngFor="let measurement of measurementList">
                        <button type="button" class="btn btn-primary rounded-pill" (click)="changeMeasurement(category)">{{
                            measurement.measurementName}}</button>
                    </ng-container>
                </div>
        
            </div>
        </div>
        <div class="form-row mb-4">
            <div class="col">
                <label for="propertySize">Property Size Min <span class="text-danger">*</span></label>
        
                <input type="text" class="form-control" id="propertySize" name="propertySizeMax"
                    formControlName="propertySizeMin">
                <span
                    *ngIf="(propform.submitted || propertySizeMin.touched) && propertySizeMin.errors && propertySizeMin.errors.required"
                    style="color: red">Property Size max is required.</span>
            </div>
        
            <div class="col">
                <label for="propertySize">Property Size Max <span class="text-danger">*</span></label>
        
                <input type="text" class="form-control" id="propertySize" name="propertySizeMax"
                    formControlName="propertySizeMax">
                <span
                    *ngIf="(propform.submitted || propertySizeMax.touched) && propertySizeMax.errors && propertySizeMax.errors.required"
                    style="color: red">Property Size max is required.</span>
        
            </div>
        
        
        </div>
        
            <div>
            <ng-container formArrayName="basicAttributes"
                *ngFor="let control of propertyForm.get('basicAttributes')['controls']; index as bAttrInd">
                <ng-container [formGroupName]="bAttrInd">
                    <div class="form-row mb-4">
                    <div class="col-md-12">
                        <ng-container *ngIf="baiscAttrList[bAttrInd].displayType === 1">
                            <label for="{{'basicAttr' + bAttrInd}}">{{baiscAttrList[bAttrInd].attributeName}} <span
                                    class="text-danger">*</span> </label>
                            <!-- <select class="form-control custom-select" id="{{'basicAttr' + bAttrInd}}"
                                            name="{{'basicAttr' + bAttrInd}}" formControlName="attrValue" (change)="prepareSeoTitle(true)">
                                            <option value="">Select</option>
                                            <option *ngFor="let prop of baiscAttrList[bAttrInd].attributeProperties"
                                                value="{{prop.propertyId}}">{{prop.swatchName}}</option>
                                        </select> -->
                            <div class="properties_btn">
                                <ng-container *ngFor="let prop of baiscAttrList[bAttrInd].attributeProperties">
        
                                    <!-- <input type="radio" class="custom-control-input" id="{{category.categoryId}}" name="categoryId"
                                                value="{{category.categoryId}}" formControlName="categoryId">
                                            <label class="custom-control-label" for="{{category.categoryId}}">{{category.categoryName}}</label> -->
                                    <button type="button" class="btn btn-primary rounded-pill"
                                        [ngClass]="{'active':prop.selected === true}"
                                        (click)="selectAttribute(prop,baiscAttrList[bAttrInd].attributeProperties,baiscAttrList[bAttrInd])">{{
                                        prop.swatchName}}</button>
        
                                </ng-container>
                            </div>
                            <span *ngIf="propform.submitted && !isvalidAttr(baiscAttrList[bAttrInd].attributeId)"
                                style="color:red;" id="dateAvailableRequired">
                                {{baiscAttrList[bAttrInd].attributeName === 'BHK' ?
                                (selectedCountry.bedroomInputType ? selectedCountry.bedroomInputType :
                                baiscAttrList[bAttrInd].attributeName) :
                                baiscAttrList[bAttrInd].attributeName}}
                                is required.
                            </span>
        
                        </ng-container>
                        <ng-container *ngIf="baiscAttrList[bAttrInd].displayType === 2">
        
        
        
                            <label for="{{'basicAttr' + bAttrInd}}">{{baiscAttrList[bAttrInd].attributeName}} <span
                                    class="text-danger">*</span> </label>
                            <!-- <select class="form-control custom-select" id="{{'basicAttr' + bAttrInd}}"
                                            name="{{'basicAttr' + bAttrInd}}" formControlName="attrValue" (change)="prepareSeoTitle(true)">
                                            <option value="">Select</option>
                                            <option *ngFor="let prop of baiscAttrList[bAttrInd].attributeProperties"
                                                value="{{prop.propertyId}}">{{prop.swatchName}}</option>
                                        </select> -->
                            <div class="properties_btn">
                                <ng-container *ngFor="let prop of baiscAttrList[bAttrInd].attributeProperties">
        
                                    <!-- <input type="radio" class="custom-control-input" id="{{category.categoryId}}" name="categoryId"
                                                value="{{category.categoryId}}" formControlName="categoryId">
                                            <label class="custom-control-label" for="{{category.categoryId}}">{{category.categoryName}}</label> -->
                                    <button type="button" class="btn btn-primary rounded-pill"
                                        [ngClass]="{'active':prop.selected === true}"
                                        (click)="selectAttribute(prop,baiscAttrList[bAttrInd].attributeProperties,baiscAttrList[bAttrInd])">{{
                                        prop.swatchName}}</button>
                                </ng-container>
                            </div>
                            <span *ngIf="propform.submitted && !isvalidAttr(baiscAttrList[bAttrInd].attributeId)"
                                style="color:red;" id="dateAvailableRequired">
                                {{baiscAttrList[bAttrInd].attributeName === 'BHK' ?
                                (selectedCountry.bedroomInputType ? selectedCountry.bedroomInputType :
                                baiscAttrList[bAttrInd].attributeName) :
                                baiscAttrList[bAttrInd].attributeName}}
                                is required.
                            </span>
        
                        </ng-container>
                        <ng-container *ngIf="baiscAttrList[bAttrInd].displayType ===3">
        
        
        
                            <label for="{{'basicAttr' + bAttrInd}}">{{baiscAttrList[bAttrInd].attributeName}} <span
                                    class="text-danger">*</span> </label>
                            <!-- <select class="form-control custom-select" id="{{'basicAttr' + bAttrInd}}"
                                            name="{{'basicAttr' + bAttrInd}}" formControlName="attrValue" (change)="prepareSeoTitle(true)">
                                            <option value="">Select</option>
                                            <option *ngFor="let prop of baiscAttrList[bAttrInd].attributeProperties"
                                                value="{{prop.propertyId}}">{{prop.swatchName}}</option>
                                        </select> -->
                            <div class="properties_btn">
                                <ng-container *ngFor="let prop of baiscAttrList[bAttrInd].attributeProperties">
        
                                    <!-- <input type="radio" class="custom-control-input" id="{{category.categoryId}}" name="categoryId"
                                                value="{{category.categoryId}}" formControlName="categoryId">
                                            <label class="custom-control-label" for="{{category.categoryId}}">{{category.categoryName}}</label> -->
                                    <button type="button" class="btn btn-primary rounded-pill"
                                        [ngClass]="{'active':prop.selected === true}"
                                        (click)="selectAttribute(prop,baiscAttrList[bAttrInd].attributeProperties,baiscAttrList[bAttrInd])">{{
                                        prop.swatchName}}</button>
                                </ng-container>
                            </div>
                            <span *ngIf="propform.submitted && !isvalidAttr(baiscAttrList[bAttrInd].attributeId)"
                                style="color:red;" id="dateAvailableRequired">
                                {{baiscAttrList[bAttrInd].attributeName === 'BHK' ?
                                (selectedCountry.bedroomInputType ? selectedCountry.bedroomInputType :
                                baiscAttrList[bAttrInd].attributeName) :
                                baiscAttrList[bAttrInd].attributeName}}
                                is required.
                            </span>
        
                        </ng-container>
                        <ng-container *ngIf="baiscAttrList[bAttrInd].displayType === 4">
        
        
        
                            <ng-container *ngIf="baiscAttrList[bAttrInd].textBoxType === 1">
                                <label>{{ baiscAttrList[bAttrInd].attributeName}}<span class="text-danger"
                                        *ngIf="baiscAttrList[bAttrInd].required">*</span>
                                </label>
                  
                                    <input type="text" class="form-control" id="projectName" formControlName="attrValue"
                                        value="{{baiscAttrList[bAttrInd].value}}" name="projectName"
                                        (keyup)="checkProperties(baiscAttrList[bAttrInd].attributeId,$event)">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{selectedCountry.currency}}</span>
                                        <!-- <select class="form-control h-100 bg-white textus" name="priceCurrency" id="priceCurrency"
                                                                                formControlName="priceCurrency" (change)="currencyChange(priceCurrency.value)">
                                                                                <option *ngFor="let country of countryList" value="{{country.codeId}}">{{country.currency}}</option>
                                                                            </select> -->
                                    </div>
                                
        
        
                            </ng-container>
                            <ng-container *ngIf="baiscAttrList[bAttrInd].textBoxType === 2">
        
        
                                <label>{{baiscAttrList[bAttrInd].attributeName}}<span class="text-danger"
                                        *ngIf="baiscAttrList[bAttrInd].required">*</span>
                                </label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="projectName" formControlName="attrValue"
                                        value="{{baiscAttrList[bAttrInd].value}}" name="projectName"
                                        (keyup)="checkProperties(baiscAttrList[bAttrInd].attributeId,$event)">
                                </div>
        
                            </ng-container>
                            <ng-container *ngIf="baiscAttrList[bAttrInd].textBoxType === 3">
        
        
                                <label>{{ baiscAttrList[bAttrInd].attributeName}}<span class="text-danger"
                                        *ngIf="baiscAttrList[bAttrInd].required">*</span>
                                </label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="yyyy-mm-dd" [minDate]="currentDate"
                                        ngbDatepicker #d="ngbDatepicker" formControlName="attrValue"
                                        (dateSelect)="checkDateProperties(baiscAttrList[bAttrInd].attributeId, $event)"
                                        value="{{ baiscAttrList[bAttrInd].value}}">
                                    <div class="input-group-append">
                                        <button class="input-group-text" type="button" (click)="d.toggle()"><i
                                                class="fal fa-calendar-alt"></i></button>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="baiscAttrList[bAttrInd].textBoxType === 4">
        
        
                                <label>{{ baiscAttrList[bAttrInd].attributeName}}<span class="text-danger"
                                        *ngIf="baiscAttrList[bAttrInd].required">*</span>
                                </label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="projectName" formControlName="attrValue"
                                        value="{{ baiscAttrList[bAttrInd].value}}" name="projectName"
                                        (keyup)="checkProperties(baiscAttrList[bAttrInd].attributeId,$event)">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!baiscAttrList[bAttrInd].textBoxType">
        
        
                                <label>{{ baiscAttrList[bAttrInd].attributeName}}<span class="text-danger"
                                        *ngIf="baiscAttrList[bAttrInd].required">*</span>
                                </label>
                                <input type="text" class="form-control" id="projectName" formControlName="attrValue"
                                    value="{{ baiscAttrList[bAttrInd].value}}" name="projectName"
                                    (keyup)="checkProperties(baiscAttrList[bAttrInd].attributeId,$event)">
        
        
                            </ng-container>
                            <span
                                *ngIf="propform.submitted &&  baiscAttrList[bAttrInd].required && !isvalidSubCategoryAttr(baiscAttrList[bAttrInd].attributeId)"
                                style="color:red;" id="dateAvailableRequired">
        
                                {{baiscAttrList[bAttrInd].attributeName}}
                                is required.
                            </span>
        
        
                        </ng-container>
                    </div>
                </div>
                </ng-container>
            
            </ng-container>
            <!--             
                                    <div class="form-group col-md-4">
                                        <label for="dateAvailable">Date Available <span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="yyyy-mm-dd" id="dateAvailable" name="dp"
                                                [minDate]="currentDate" ngbDatepicker #d="ngbDatepicker" formControlName="dateAvailable">
                                            <div class="input-group-append">
                                                <button class="input-group-text" type="button" (click)="d.toggle()"><i
                                                        class="fal fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <span
                                            *ngIf="(propform.submitted || dateAvailable.touched) && dateAvailable.errors && dateAvailable.errors.required"
                                            style="color: red">Date Available is required.</span>
                                
                                        <span
                                            *ngIf="(propform.submitted || dateAvailable.touched) && dateAvailable.errors && dateAvailable.errors.ngbDate && dateAvailable.errors.ngbDate.invalid"
                                            style="color: red">Date should be in YYYY-MM-DD.</span>
                                
                                        <span
                                            *ngIf="(propform.submitted || dateAvailable.touched) && dateAvailable.errors && dateAvailable.errors.ngbDate && dateAvailable.errors.ngbDate.minDate"
                                            style="color: red">Date should be future date.</span>
                                    </div> -->
            </div>
      
        <div class="form-row mb-4">
            <div class="col">
                <div class="form-group">
                    <label for="propertyDescription">Project Description<span class="text-danger">*</span></label>
                    <textarea class="form-control" rows="4" id="propertyDescription" name="projectDescription"
                        formControlName="projectDescription"></textarea>
                    <span
                        *ngIf="(propform.submitted || projectDescription.touched) && projectDescription.errors && projectDescription.errors.required"
                        style="color: red">Description is required.</span>
                </div>
            </div>
        </div>
            <div class="form-row mb-4">
                <div class="col">
                    <div class="form-group">
                        <label for="propertyTitle">Name (if you want to change the vendor display details for user please change
                            it)<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="name" name="name" formControlName="name">
                        <span *ngIf="(propform.submitted || name.touched) && name.errors && name.errors.required"
                            style="color: red">name is required.</span>
                    </div>
                </div>
            </div>
        <div class="form-row mb-4">
            <div class="col">
                <div class="form-group">
                    <label for="propertyTitle">Email(if you want to change the vendor display details for user please change it) <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="email" name="email" formControlName="email">
                    <span *ngIf="(propform.submitted || email.touched) && email.errors && email.errors.required"
                        style="color: red">Email is required.</span>
                </div>
            </div>
            <div class="col">
                    <label for="propertyTitle">Mobile (if you want to change the vendor display details for user please change it)<span class="text-danger">*</span></label>
    
                    <ngx-intl-tel-input [cssClass]="'form-control'" [onlyCountries]="onlyCountries"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="false"
                    [selectFirstCountry]="false" [maxLength]="15" [tooltipField]="TooltipLabel.Name"
                    style="width: 100%;" [phoneValidation]="true" [separateDialCode]="true" id="mobile"
                    [selectedCountryISO]="selectedISOCountry" name="mobile" formControlName="mobile">
                </ngx-intl-tel-input>
                                 
            </div>
        </div>

        <div class="form-row mb-4">
            <div class="col">
                    <label for="propertyTitle">Whats App Number</label>
                    <input type="text" class="form-control" id="whatsAppNumber" name="whatsAppNumber" formControlName="whatsAppNumber">
                    <span *ngIf="(propform.submitted || whatsAppNumber.touched) && whatsAppNumber.errors && whatsAppNumber.errors.required"
                        style="color: red">whatsApp Number is required.</span>
                <!-- <div class="card-body">
                    <h5 class="card-title mb-4">Why This Project   <a (click)="addSwatch()">Add New</a></h5>
                    <div class="row">
                        <div class="form-group col-md-12" *ngFor="let swatch of swatchList; let i = index;">
                    
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Highlights" id="location" [(ngModel)]="swatchList[i].swatchName" >
                            </div>
                        </div>
                    </div>
                    
                </div> -->
            </div>
        </div>
        <div>
        <button type="submit" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
        <button type="button" (click)="back()" class="btn btn-gray btn-lg mb-2 float-right mr-2">Back</button>
    </div>

    </form>
</div>