import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';


@Component({
  selector: 'app-property-modules',
  templateUrl: './property-modules.component.html',
  styleUrls: ['./property-modules.component.css']
})
export class PropertyModulesComponent implements OnInit {
  moduleList = [];
  childModuleList = [];
  constructor(private route: Router,
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
  ) { }

  ngOnInit(): void {
    this.loadModuleList();
  }
  rentProperty() {
    this.route.navigate(['addRent/new'])
  }
  pgHostelProperty() {
    this.route.navigate(['addPg/new'])
  }
  SaleProperty() {
    this.route.navigate(['post-property/new'])

  }
  getIcon(val: string): string {
    switch (val) {
      case 'Rent':
        return 'assets/img/Rent.svg';
      case 'Sale':
        return 'assets/img/Buy.svg';
      case 'Pg/Hostels':
        return 'assets/img/Pg&hostel.svg';
      default:
        return '';
    }
  }
  getIconAltModules(val: string): string {
    switch (val) {
      case 'Homes':
        return 'assets/img/Commercial.svg';
        case 'Plot & Land':
          return 'assets/img/Plot & Land.svg';
      case 'Commercial':
        return 'assets/img/Commercial.svg';
      default:
        return '';
    }
  }
  loadModuleList(): void {
   const vendorId=this.lookupModel.getLoggedUser().vendorTypeId;
    this.rsbService.getAllMainModules({}).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        if (rsp.contents.length > 0) {
         
          this.moduleList = rsp.contents;
          this.moduleList=this.moduleList.filter((data)=>{
           return data || data.vendorTypeId.includes(vendorId)
          })
        }
        else {
          this.moduleList = [];
        }
      }
    })
  }
  selectModuleType(module: any) {
    this.lookupModel.setModuleId(module.moduleId)
    localStorage.setItem('mainModuleId',module.mainModuleId);
    localStorage.setItem('mappedModuleId',JSON.stringify(module.moduleId));
 
      switch (module.moduleName) {
      case 'Rent':
        return this.route.navigate(['addRent/new']);
      case 'Pg/Hostels':
        return this.route.navigate(['addPg/new']);
      case 'Sale':
        this.childModuleList = this.lookupModel.getModuleList().filter((obj) => {
          return module.moduleId?.includes(obj.moduleId);
        })
      default:
        return undefined;

    }

  }

  selectChildModuleList(module:any) {
    localStorage.setItem('childModuleId',module.moduleId)
    switch (module.altModuleName) {
      case 'Homes':
        return this.route.navigate(['post-property/new']);
      case 'Commercial':
        return this.route.navigate(['post-property/new']);
      case 'Plot & Land':
        return this.route.navigate(['Add-plot-And-Land/new']); 
      default:
        return undefined;
    }
  }



}
